export const getters = {
    getterOrders(state) {
        return state.dashboards.orders
    },
    getterTickets(state) {
        return state.dashboards.support
    },
    getterBookmarks(state) {
        return state.dashboards.bookmarks
    },
    getterOrderCounter(state) {
        return state.orderCounter
    },
    getterOpenTickets(state) {
        return state.ticketsOpenCounter
    },
    gettersFeedbacksCounter(state) {
        return state.feedbacksCounter
    },
    getterEarnings() {
        return [
            {
                title: 'Request Payout',
                value: 'request'
            }
        ]
    }
}
