import Vue from 'vue'
import Vuex from 'vuex'
import { eventBus } from '@/helpers/event-bus'
import { writer } from '@/store/modules/writer/index.js'
import { phone } from '@/store/modules/phone/index.js'
import { support } from '@/store/modules/support/index.js'
import { config } from '@/store/modules/config/index.js'
import createPersistedState from 'vuex-persistedstate'
import { VuexHelper } from '@/helpers/vuex/index.js'
import { wizard } from './modules/wizard';
import { bookmarks } from './modules/bookmarks';
import { dashboards } from './modules/dashboards';
import { exam } from './modules/exam';

function checkerBuildId() {
    const instance = new VuexHelper(process.env.VUE_APP_BUILD)
    if (instance.hasChanged()) {
        console.log('%c Resetting vuex store!', 'background: #222; color: #bada55')
        setTimeout(() => {
            eventBus.$emit('showSnackBar', 'The app was updated!', 'success')
        }, 0);
        instance.reset()
        instance.setNewId()
        return true
    }
    return false
}

checkerBuildId()

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState()
    ],
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        writer,
        phone,
        wizard,
        support,
        config,
        bookmarks,
        dashboards,
        exam
    }
})
