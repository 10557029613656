import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils';

export const createTicket = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/create-ticket' : '/api/support/guest/create-ticket'
    const { data } = await Api.post(api_path, removeEmptyParamsFromRequest({
        ...payload,
        chk_callback: false
    }))
    return data
}

export const markAsReadTicket = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/mark-as-read' : '/api/support/guest/mark-as-read'
    await Api.put(api_path, removeEmptyParamsFromRequest(payload))
}

export const closeTicket = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/close-ticket' : '/api/support/guest/close-ticket'
    await Api.put(api_path, removeEmptyParamsFromRequest(payload))
}

export const sendMessage = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/create-message' : '/api/support/guest/create-message'
    const { data } = await Api.post(api_path, removeEmptyParamsFromRequest({
        ticketid: payload.ticketid,
        data_support: { message: payload.message }
    }))
    return data
}

export const getGuestTicket = async (payload) => {
    const { data } = await Api.get('/api/support/guest/fetch-ticket', removeEmptyParamsFromRequest(payload))
    return data
}

export const fetchMessages = async (payload, isAuth) => {
    const api_path = isAuth ? '/api/support/auth/fetch' : '/api/support/guest/fetch'
    const { data } = await Api.get(api_path, removeEmptyParamsFromRequest(payload))
    return data
}
