const Login = () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue')
const Otp = () => import(/* webpackChunkName: "OTP" */ '@/views/login/Otp.vue')
const Register = () => import(/* webpackChunkName: "register" */ '@/views/register/Register.vue')
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ '@/views/forgot_password/ForgotPassword.vue')
const Reset = () => import(/* webpackChunkName: "_reset" */'@/views/reset_password/ResetPassword')
const ResetSuccess = () => import(/* webpackChunkName: "_reset" */'@/views/reset_password/ResetPasswordSuccess')
// const NotificationSettings = () => import(/* webpackChunkName: "_reset" */'#//pages/NotificationSettingsPage')
const Oauth = () => import(/* webpackChunkName: "_reset" */'@/views/login/Oauth')

export default [
    {
        path: '/login',
        component: Login,
        name: 'login'
    },
    {
        path: '/otp',
        component: Otp,
        name: 'otp'
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password',
        props: {
            mode: 'forgot'
        }
    },
    {
        path: '/register',
        component: Register,
        name: 'register'
    },
    {
        // reset password form for client
        path: '/auth/client/password/show-reset-form/:token',
        component: Reset,
        name: 'reset'
    },
    {
        // reset success
        path: '/reset-success/:token',
        component: ResetSuccess,
        name: 'reset-success'
    },
    //     {   // change e-mail settings for client
    //         path: '/auth/client/notification-settings/change/:token',
    //         component: NotificationSettings,
    //         name: 'notification-settings'
    //     },
    {
        path: '/oauth/registration-complete/:service',
        component: Oauth,
        name: 'oauth'
    }
]
