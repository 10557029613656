<template>
    <div
        v-if="dialog"
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>English Examination</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="confirm-modal__content">
                <span>Please take the English exam to verify your command of the English language.</span>
            </section>
            <footer>
                <div class="confirm-modal__two-button-block">
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--gray"
                        @click.prevent="change()"
                    >
                        skip
                    </button>
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--main"
                        @click.prevent="submit()"
                    >
                        go to exam
                        <open-in-new />
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OpenInNew from 'mdi-vue/OpenInNew'

export default {
    name: 'ConfirmModal',
    components: {
        OpenInNew
    },
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapGetters('writer', [
            'getterNeedUpdateExam',
            'getterIsAuth'
        ])
    },
    methods: {
        open() {
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        submit() {
            this.close()
            this.$router.push({ name: 'documents-exam' })
        }
    }
}
</script>

<style lang="scss">
.confirm-modal {
    .form-input__fieldset {
        margin: 0;
    }
    .phone-container {
        margin: 20px 0;
    }
}
</style>
