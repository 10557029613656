<template>
    <div class="avatar-wrapper">
        <div
            :class="className"
        >
            <div
                v-if="getAvatarSrc"
                class="avatar-img"
            >
                <img
                    :src="getAvatarSrc"
                    alt="avatar"
                    class="avatar-user"
                >
            </div>
            <div
                v-else
                class="avatar-img"
                :class="user"
                :style="{backgroundColor}"
            >
                <span
                    v-if="getInitials"
                    class="initials"
                >
                    {{ getInitials }}
                </span>
                <div
                    v-else
                    class="default-avatar"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        user: {
            type: String
        },
        avatarFallback: {
            type: String,
            required: false
        },
        avatarUrl: {
            type: String,
            required: false
        },
        nickname: {
            type: String,
            required: false
        },
        firstname: {
            type: String,
            required: false
        },
        lastname: {
            type: String,
            required: false
        },
        className: {
            type: String,
            required: false,
            default: 'avatar-person'
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'transperent'
        }
    },
    computed: {
        getInitials() {
            if (this.nickname) {
                return this.nickname.charAt(0)
            }
            if (this.firstname && this.lastname) {
                return `${this.firstname.charAt(0)}${this.lastname.charAt(0) ?? ''}`
            }
            if (this.firstname) {
                return this.firstname.charAt(0)
            }
            if (this.email) {
                return this.email.charAt(0)
            }
            return false
        },
        getAvatarSrc() {
            if (this.avatarUrl && !this.avatarUrl.includes(`https://${process.env.VUE_APP_DOMAIN}`)) {
                return this.avatarUrl
            }
            if (this.avatarFallback) {
                try {
                    /*eslint-disable */
                    return require(`@/assets/img/avatars/${this.avatarFallback}`)
                    /* eslint-enable */
                } catch (error) {
                    return false
                }
            }
            return false
        }
    }
}
</script>
<style lang="scss">
    .avatar {
        &-person, &-list {
            height: 100%;
        }

        &-img {
            background-color: rgba(25, 118, 210, 0.1);
            color: #1976d2;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            .initials {
                font-size: 24px;
                font-weight: bold;
                text-transform: capitalize;
            }
            .default-avatar {
                mask-image: url('~@/assets/img/svg/default-avatar.svg');
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                background: #fff;
                width: 20px;
                height: 21px;
            }
            &.client {
                background: rgba(39, 174, 96, 0.1);
                .initials {
                    color: #27ae60;
                }
            }
            &.admin {
                background: rgba(155,81,224,.1);
                .initials {
                    color: #9b51e0;
                }
            }
        }
    }
</style>
