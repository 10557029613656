import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getterExamStatus(state) {
        return state.examStatus
    },
    getterCurentQuestion(state) {
        return state.question
    },
    getterCurentAnswers(state) {
        return state.answers
    },
    getterQuestionsLeft(state) {
        return state.questions_left
    },
    getterQuestionsTotal(state) {
        return state.questions_total
    },
    getterExpires(state) {
        return state.expires_at
    },
    getterSaveAnswerLoading(state) {
        return state.answerLoading
    },
    getterCountdown(state) {
        return state.countdown
    },
    getterToken(state) {
        return state.token
    }
}
