import {
    required,
    numeric
} from 'vuelidate/lib/validators';

export const validationRules = {
    country: {
        required,
        objectStructure(value) {
            if (!value.value || !value.iso) { return false }
            return true
        }
    },
    phone_number: {
        required,
        numeric,
        minLength() {
            const fullTelNumberLength = (String(this.country.value) + this.phone_number).length
            if (fullTelNumberLength <= 8) {
                return false
            }
            return true
        },
        async isUniquePhoneNumber() {
            let response
            const fullTelNumberLength = (String(this.country.value) + this.phone_number).length
            if (this.country.value && this.phone_number && (fullTelNumberLength >= 8) && (fullTelNumberLength <= 21)) {
                response = await this.$store.dispatch('writer/ACTION_WRITER_CHECK_PHONE_EXISTS', {
                    phone_code: `${this.country.value}`,
                    phone: `${this.phone_number}`
                })
            }
            return response?.data?.status
        }
    }
}
