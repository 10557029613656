import Api from '@/helpers/api/index.js';

import {
    ACTION_TICKET_SUBMIT,
    ACTION_TICKET_ASK_REPLY,
    ACTION_TICKET_SUBMIT_REPLY
} from './action-types.js'

import {
    SET_TICKETID,
    CLEAR_FORM
} from './mutation-types.js'

export const actions = {
    async [ACTION_TICKET_SUBMIT]({ state, commit }) {
        const data = {
            data_support: { ...state.form_data.data_support }
        }
        if (!data.data_support.linked_to_order) {
            delete data.data_support.linked_to_order
        }
        const { data: response } = await Api.post('/api/support/auth/create-ticket', data)
        commit(CLEAR_FORM)
        commit('phone/RESET', null, { root: true })
        return response
    },
    [ACTION_TICKET_ASK_REPLY]({ commit }, payload) {
        return new Promise(() => {
            commit(SET_TICKETID, {
                id: payload.id
            })
        })
    },
    async [ACTION_TICKET_SUBMIT_REPLY]({ rootState }, { message }) {
        const dataInput = {
            ticketid: rootState.support.form_data.ticketid,
            data_support: {
                message
            }
        }
        const data = JSON.stringify(dataInput)
        await Api.post('/api/support/auth/create-message', data)
    }
}
