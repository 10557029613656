import Api from '@/helpers/api/index.js'
import {
    ACTION_CONFIRM_PIN
} from './action-types'
import {
    SUCCESS,
    FAILURE
} from './mutation-types'

export const actions = {
    async [ACTION_CONFIRM_PIN]({ commit }, payload) {
        try {
            await Api.post('/api/phone-verification/check-pin', {
                phone_сountry: payload.phone_code.toString(),
                phone: payload.phone,
                pin: payload.pin
            })
            commit(SUCCESS)
            // resolve()
        } catch (error) {
            console.error(error)
            commit(FAILURE)
        }
    }
}
