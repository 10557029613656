<template lang="html">
    <div
        v-if="myOreders.length"
        class="row align-center"
    >
        <div class="col-6 specific_order">
            <form-switch
                id="chk_linked"
                v-model="chk_linked"
            />
            <span class="form-switch_text">{{ label }}</span>
        </div>
        <!-- orderid -->
        <div
            v-if="chk_linked"
            class="col-6"
            :class="errors ? 'form-select_order' : ''"
        >
            <form-select-orders
                v-model="$v.linked_to_order.$model"
                label=" Pretype order ID or choose from the list..."
                :orders="myOreders"
                :err="Boolean(errors)"
                name="linked_to_order"
            />
            <div
                v-if="errors"
                class="form-select__error"
            >
                {{ errors }}
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/support/Rules'
import { formMessages } from '@/validation/support/Messages'
import Api from '@/helpers/api/index.js';
import filtersMixin from '@/mixins/filtersMixin.js'

import FormSwitch from '@/components/common/form-elements/FormSwitch';
import FormSelectOrders from '@/components/common/form-elements/FormSelectOrders';
import {
    mapSupportFields
} from '@/store/modules/support';

export default {
    name: 'ReferenceOrder',
    components: {
        FormSwitch,
        FormSelectOrders
    },
    mixins: [
        filtersMixin,
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    props: {
        label: {
            type: String
        },
        errors: {
            type: String
        },
        sw_id: {
            type: [String, Number],
            default: null
        }
    },
    data: () => ({
        orders: [],
        inputLoading: false
    }),
    computed: {
        ...mapSupportFields([
            'form_data.data_support.chk_linked',
            'form_data.data_support.linked_to_order'
        ]),
        myOreders() {
            return !this.sw_id ? this.orders : this.orders.filter((order) => `${order.sw_id}` === this.sw_id)
        },
        isClientStatusWriter() {
            return this.$store.state.writer.writer_data.accessstatus === 'WRITER'
        }
    },
    async mounted() {
        if (this.isClientStatusWriter) {
            this.getInitialOrdersList()
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async getInitialOrdersList() {
            const vm = this
            this.inputLoading = true
            const requestQuery = {
                per_page: 100,
                mode: 'list'
            }
            await Api.get('/api/order/list-by-params', requestQuery)
                .then((res) => {
                    vm.orders = res.data.data
                })
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => {
                    this.inputLoading = false
                    vm.$forceUpdate()
                })
        }

    }
}
</script>

<style lang="scss">
    .quote-form__form-col-100 {
        width: 100%;
    }
    .form-select_order {
        position: relative;
        .form-select {
            border: $input-border-error;
        }
    }
</style>
