export class SchemaWriter {
    constructor({
        avatar_fallback = '',
        avatar_url = '',
        timezone = '',
        fullname = '',
        nickname = '',
        firstname = '',
        lastname = '',
        email = '',
        password = '',
        address = null,
        city = null,
        zip = null,
        country = null,
        region = null,
        // schedule = '',
        // workload = '',
        // deadlines = '',
        // personal_statement = '',
        // why_hire = '',
        // other_firms = '',
        // citation_styles = '',
        // plagiarism_statement = '',
        resume = '',
        // major = '',
        paypal_email = '',
        payoneer = {},
        wire_correspondent_bank = '',
        wire_correspondent_bank_code = '',
        wire_bank = '',
        wire_beneficiary_bank_code = '',
        wire_beneficiary_bank_address = '',
        wire_beneficiary_name = '',
        wire_beneficiary_account_number = '',
        wire_beneficiary_bank_name = '',
        wire_correspondent_bank_address = '',
        wire_beneficiary_iban = '',
        wire_details = '',
        wire_fee = '',
        trust_status = '',
        sw_id = 0,
        accessstatus = '',
        name = '',
        phone = '',
        phone_code = '',
        state = '',
        status = '',
        twofa = 0,
        application
    } = {}) {
        this.avatar_fallback = avatar_fallback
        this.avatar_url = avatar_url
        this.timezone = timezone;
        this.trust_status = trust_status;
        this.fullname = fullname;
        this.nickname = nickname;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.password = password;
        this.address = address;
        this.city = city;
        this.zip = zip;
        this.country = country;
        this.region = region;
        // this.schedule = schedule;
        // this.workload = workload;
        // this.deadlines = deadlines;
        // this.personal_statement = personal_statement;
        // this.why_hire = why_hire;
        // this.other_firms = other_firms;
        // this.citation_styles = citation_styles;
        // this.plagiarism_statement = plagiarism_statement;
        this.resume = resume;
        // this.major = major;
        this.paypal_email = paypal_email;
        this.payoneer = payoneer;
        this.wire_correspondent_bank = wire_correspondent_bank;
        this.wire_correspondent_bank_code = wire_correspondent_bank_code;
        this.wire_bank = wire_bank;
        this.wire_beneficiary_bank_code = wire_beneficiary_bank_code;
        this.wire_beneficiary_bank_address = wire_beneficiary_bank_address;
        this.wire_beneficiary_name = wire_beneficiary_name;
        this.wire_beneficiary_account_number = wire_beneficiary_account_number;
        this.wire_beneficiary_bank_name = wire_beneficiary_bank_name;
        this.wire_correspondent_bank_address = wire_correspondent_bank_address;
        this.wire_beneficiary_iban = wire_beneficiary_iban;
        this.wire_details = wire_details;
        this.wire_fee = wire_fee;
        this.sw_id = sw_id;
        this.accessstatus = accessstatus;
        this.name = name;
        this.phone = phone;
        this.phone_code = phone_code;
        this.state = state;
        this.status = status;
        this.twofa = twofa
        this.application = application
    }
}
