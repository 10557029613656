<template>
    <div>
        <div class="custom-loader" />
    </div>
</template>
<script>
export default {
    name: 'ButtonLoader'
}
</script>

<style lang="scss">
.custom-loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #888;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    align-items: center;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
