<template>
    <div
        class="global_update-modal__dialog"
    >
        <span
            class="global_update-modal__close"
            @click="close()"
        />
        <section
            class="global_update-modal__content"
            @click.prevent="submit()"
        >
            <AlertCircleOutline
                size="20"
                class="global_update-modal__icon-alert"
            />
            <span>Please update your general information.</span>
            <open-in-new
                size="18"
                class="global_update-modal__icon-link"
            />
        </section>
    </div>
</template>

<script>
import AlertCircleOutline from 'mdi-vue/AlertCircle'
import OpenInNew from 'mdi-vue/OpenInNew'

export default {
    name: 'ConfirmModal',
    components: {
        AlertCircleOutline,
        OpenInNew
    },
    methods: {
        close() {
            this.$emit('close')
        },
        submit() {
            this.close()
            this.$emit('proceed')
        }
    }
}
</script>

<style lang="scss">
.global_update-modal {
    .form-input__fieldset {
        margin: 0;
    }
    .phone-container {
        margin: 20px 0;
    }
}
</style>
