import './assets/styles/index.scss'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import Api from '@/helpers/api/index.js'
import vClickOutside from 'v-click-outside'
import VueSkeletonLoader from 'skeleton-loader-vue';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Socket from '@/plugins/socket.js'
import store from './store'
import router from './router'
import App from './App.vue'
// custom plugins
import FormElements from './plugins/form_elements.js'

Vue.component('vue-skeleton-loader', VueSkeletonLoader);

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(vClickOutside)
Vue.use(FormElements)
Vue.use(Socket)

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', process.env.VUE_APP_SENTRY_DOMAIN, /^\//]
            })
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}

new Vue({
    router,
    store,
    Api,
    render: (h) => h(App)
}).$mount('#app')
