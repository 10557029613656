<template>
    <div
        v-if="getterIsAuth"
        :key="key"
        class="support-messenger__wrapper"
    >
        <div
            class="support-messenger__button"
            @click="showMessenger = !showMessenger"
        >
            <FaceAgent v-if="!showMessenger" />
            <ChevronDown v-else />
        </div>
        <transition
            mode="out-in"
            name="slide-y"
        >
            <div
                v-if="showMessenger"
                class="support-messenger"
            >
                <SupportMessengerHeader
                    :type="headerType"
                    :show-avatars="Boolean(listTickets.length)"
                    @goToBack="goToList"
                    @closeMessenger="closeMessenger"
                />

                <div
                    class="support-messenger_tabs"
                >
                    <div
                        class="support-messenger_tab support-tab"
                        :class="{'support-messenger_tab--active' : (state === 'list' || state === 'chat')}"
                        @click="goToTickets"
                    >
                        <span>Support</span>
                    </div>
                    <div
                        class="support-messenger_tab clients-tab"
                        :class="{'support-messenger_tab--active' : state === 'clients' || state === 'client-chat'}"
                        @click="goToClients"
                    >
                        <span>Clients</span>
                    </div>
                    <div
                        class="support-messenger_tab faq-tab"
                        :class="{'support-messenger_tab--active' : state === 'faq'}"
                        @click="state = 'faq'"
                    >
                        <span>FAQ</span>
                    </div>
                </div>

                <div
                    class="support-messenger_body"
                    :class="{
                        [`support-messenger_body--header-${headerType}`]: true,
                        'support-messenger_body--scroll': (state !== 'chat' || state !== 'client-chat'),
                        'support-messenger_body--scroll-visible': listTickets.length === 0 && state !== 'create',
                        'support-messenger_body--chat': (state === 'chat' || state === 'client-chat'),
                    }"
                >
                    <transition
                        mode="out-in"
                        name="bounce"
                    >
                        <clients
                            v-if="state === 'clients'"
                            :list="listClientsChat"
                            :loading="isLoading"
                            @goToChat="goToChat($event, 'client-chat')"
                        />
                    </transition>
                    <transition
                        mode="out-in"
                        name="bounce"
                    >
                        <faq
                            v-if="state === 'faq'"
                            @goToCreate="goToCreate"
                        />
                    </transition>
                    <transition
                        mode="out-in"
                        name="bounce"
                    >
                        <SupportMessengerList
                            v-if="state === 'list' && listTickets.length"
                            :list="listTickets"
                            :loading="isLoading"
                            @goToChat="goToChat($event, 'support-chat')"
                        />
                    </transition>
                    <transition
                        mode="out-in"
                        name="bounce"
                    >
                        <SupportMessengerCreate
                            v-if="state === 'create'"
                            :ticket-creating="ticketCreating"
                            @createTicket="createTicket"
                        />
                    </transition>
                    <transition
                        mode="out-in"
                        name="bounce"
                    >
                        <SupportMessengerEmpty
                            v-if="state === 'list' && !listTickets.length"
                            :class="{ 'with-tabs':getterIsAuth }"
                            @goToCreate="goToCreate"
                        />
                    </transition>
                    <transition
                        mode="out-in"
                        name="bounce"
                    >
                        <SupportMessengerChat
                            v-if="state === 'chat'"
                            :messages="messages"
                            :hash="getHash"
                            :ticketid="currentTicket"
                            :list-loading="isLoading"
                            @readMessages="readMessages"
                        />
                    </transition>
                    <transition
                        mode="out-in"
                        name="bounce"
                    >
                        <ClientsMessengerChat
                            v-if="state === 'client-chat'"
                            :chat-id="currentClientChat"
                            :messages="clientMessages"
                            :list-loading="isLoading"
                            @readMessages="readMessagesClientsChat"
                        />
                    </transition>
                </div>

                <div
                    v-if="state === 'list' && listTickets.length"
                    class="support-messenger_footer"
                >
                    <button
                        class="btn-base btn-main"
                        @click="state = 'create'"
                    >
                        New conversation
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import {
    createTicket, getGuestTicket, fetchMessages
} from '@/services/support/supportApi'
import {
    getClientsChatsList,
    getClientChatMessages
} from '@/services/clientsChat'
import {
    setGuestTicket, getGuestTicketFromStorage, removeGuestTicketFromStorage
} from '@/services/support'

import SupportMessengerHeader from '@/components/support/supportMessenger/SupportMessengerHeader'
import SupportMessengerList from '@/components/support/supportMessenger/SupportMessengerList'
import SupportMessengerCreate from '@/components/support/supportMessenger/SupportMessengerCreate'
import SupportMessengerEmpty from '@/components/support/supportMessenger/SupportMessengerEmpty'
import SupportMessengerChat from '@/components/support/supportMessenger/SupportMessengerChat'
import FaceAgent from 'mdi-vue/FaceAgent.vue'
import ChevronDown from 'mdi-vue/ChevronDown.vue'
import Clients from '@/components/support/supportMessenger/Clients'
import Faq from '@/components/support/supportMessenger/Faq'
import ClientsMessengerChat from '@/components/support/supportMessenger/ClientsMessengerChat.vue'

import { mapSupportFields } from '@/store/modules/support';

const updateInterval = 6 * 10 * 1000

export default {
    components: {
        SupportMessengerHeader,
        SupportMessengerList,
        SupportMessengerCreate,
        SupportMessengerEmpty,
        SupportMessengerChat,
        FaceAgent,
        ChevronDown,
        Clients,
        Faq,
        ClientsMessengerChat
    },
    data() {
        return {
            key: 0,
            isLoading: true,
            ticketCreating: false,
            listTickets: [],
            intervalId: null,
            showMessenger: false,
            messages: [],
            listClientsChat: [],
            currentClientChat: '',
            clientMessages: []
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterIsAuth'
        ]),
        ...mapSupportFields([
            'state',
            'currentTicket'
        ]),
        headerType() {
            return this.state === 'create' || this.state === 'chat' || this.state === 'client-chat' ? 'small' : 'normal'
        },
        getHash() {
            if (this.state === 'chat') {
                const ticket = this.listTickets.find((item) => item.ticketid === this.currentTicket)
                return ticket.hash
            }
            return []
        }
    },
    watch: {
        async getterIsAuth(val) {
            clearTimeout(this.intervalId)
            this.state = 'list'
            this.currentTicket = ''
            this.listTickets = []
            await this.getTickets()
            if (val) {
                this.intervalId = setTimeout(async () => {
                    await this.liveUpdate()
                }, updateInterval);
            } else {
                clearTimeout(this.intervalId)
            }
        }
    },
    async created() {
        this.state = 'list'
        await this.getTickets()
        this.intervalId = setTimeout(async () => {
            await this.liveUpdate()
        }, updateInterval);

        eventBus.$on('openMessenger', async (id, type = 'support-chat') => {
            this.showMessenger = true
            await this.goToChat(id, type)
            this.$nextTick(() => {
                eventBus.$emit('openChat', id, type)
            })
        });
    },
    methods: {
        async goToClients() {
            this.state = 'clients'
            this.currentClientChat = ''
            this.clientMessages = []
            await this.getClientsChats()
        },
        async goToTickets() {
            this.state = 'list'
            this.currentTicket = ''
            this.messages = []
            await this.getTickets()
        },
        async goToList() {
            if (this.state === 'client-chat') {
                this.goToClients()
                return
            }
            await this.goToTickets()
        },
        goToCreate() {
            this.state = 'create'
            this.currentTicket = ''
        },
        async goToChat(id, type) {
            switch (type) {
            case 'support-chat':
                this.fetchSupportMessages(id)
                break
            case 'client-chat':
                this.fetchClientsMessages(id)
                break
            default:
                this.currentTicket = id
                this.messages = await fetchMessages({ ticketid: this.currentTicket }, this.getterIsAuth)
                this.state = 'chat'
            }
        },
        async fetchSupportMessages(id) {
            try {
                this.isLoading = true
                this.currentTicket = id
                this.state = 'chat'
                this.messages = await fetchMessages({ ticketid: this.currentTicket }, this.getterIsAuth)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
                this.state = 'list'
            } finally {
                this.isLoading = false
            }
        },
        async fetchClientsMessages(id) {
            try {
                this.isLoading = true
                this.currentClientChat = id
                this.state = 'client-chat'
                this.clientMessages = await getClientChatMessages({ chat_id: id })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
                this.state = 'clients'
            } finally {
                this.isLoading = false
            }
        },
        async getClientsChats() {
            if (this.getterIsAuth) {
                try {
                    this.isLoading = true
                    this.listClientsChat = await getClientsChatsList()
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error');
                } finally {
                    this.isLoading = false
                }
            }
        },
        closeMessenger() {
            this.showMessenger = false
        },
        async getTickets() {
            if (!this.getterIsAuth) {
                const requests = []
                getGuestTicketFromStorage().forEach((ticket) => {
                    requests.push(getGuestTicket({ ticketid: ticket.ticketid, hash: ticket.hash }))
                });
                const removeIndexes = []
                await Promise.allSettled(requests)
                    .then((results) => results.forEach((result, index) => {
                        if (result.status === 'fulfilled') {
                            const findIn = this.listTickets.findIndex((item) => item.ticketid === result.value.ticketid)
                            if (findIn !== -1) {
                                this.listTickets.splice(findIn, 1, result.value)
                            } else {
                                this.listTickets.push(result.value)
                            }
                        } else {
                            removeIndexes.push(index)
                        }
                    }))
                removeGuestTicketFromStorage(removeIndexes)
                return
            }
            try {
                console.log('fetch');
                this.isLoading = true
                const { data } = await Api.get('/api/support/auth/list-by-preset', {
                    mode: 'list',
                    page: 1,
                    per_page: 10,
                    preset: 'Tickets_Open'
                }, false, false)
                this.listTickets = data.data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false
            }
        },
        async liveUpdate() {
            if (this.getterIsAuth) return
            const requests = []
            getGuestTicketFromStorage().forEach((ticket) => {
                requests.push(getGuestTicket({ ticketid: ticket.ticketid, hash: ticket.hash }))
            });
            const removeIndexes = []
            await Promise.allSettled(requests)
                .then((results) => results.forEach((result, index) => {
                    if (result.status === 'fulfilled') {
                        const findIn = this.listTickets.findIndex((item) => item.ticketid === result.value.ticketid)
                        if (findIn !== -1) {
                            this.listTickets.splice(findIn, 1, result.value)
                        } else {
                            this.listTickets.push(result.value)
                        }
                    } else {
                        removeIndexes.push(index)
                    }
                }))
            removeGuestTicketFromStorage(removeIndexes)
            this.intervalId = setTimeout(async () => {
                await this.liveUpdate()
            }, updateInterval);
        },
        async createTicket(payload) {
            try {
                this.ticketCreating = true
                const data = await createTicket(payload, this.getterIsAuth)
                this.currentTicket = data.ticketid
                this.messages = await fetchMessages({ ticketid: this.currentTicket }, this.getterIsAuth)
                if (!this.getterIsAuth) {
                    setGuestTicket(data)
                }
                clearTimeout(this.intervalId)
                await this.getTickets()
                this.state = 'chat'
            } catch (error) {
                console.log(error, 'error')
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.ticketCreating = false
            }
        },
        readMessages(ticketid) {
            const findIn = this.listTickets.findIndex((item) => item.ticketid === ticketid)
            this.listTickets.splice(findIn, 1, {
                ...this.listTickets[findIn],
                counters: {
                    unread_messages_count: 0
                }
            })
        },
        readMessagesClientsChat(chatId) {
            const findIn = this.listClientsChat.findIndex((item) => item.id === chatId)
            this.listClientsChat.splice(findIn, 1, {
                ...this.listClientsChat[findIn],
                counters: {
                    unread_messages_count: 0
                }
            })
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/common/components/_support-messenger";
</style>
