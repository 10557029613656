// // ORDER
// const OrderList = () => import('@/views/account/Order/OrderList.vue')
const OrderDetails = () => import('@/views/account/orders/OrderDetails.vue')
const Details = () => import('@/views/account/orders/Tabs/Details.vue')
const Files = () => import('@/views/account/orders/Tabs/Files.vue')
const Messages = () => import('@/views/account/orders/Tabs/Messages.vue')
const Tickets = () => import('@/views/account/orders/Tabs/Tickets.vue')
const AdditionalFiles = () => import('@/views/account/orders/Tabs/AdditionalFiles.vue')

export default [
    {
        path: '/cp/details/:id',
        component: OrderDetails,
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Order Details',
                additionalClass: 'bc-horizontal bc-horizontal__details',
                parent_route_names: ['orders']
            }
        },
        children: [
            {
                path: '',
                name: 'details',
                component: Details,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'BreadcrumbsLayout',
                    breadcrumbs: {
                        name: 'Order Details',
                        layout: 'BreadcrumbsLayout',
                        parent_route_names: ['orders']
                    }
                }
            },
            {
                path: 'files',
                name: 'order_files',
                component: Files,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'BreadcrumbsLayout',
                    breadcrumbs: {
                        name: 'Order Files',
                        layout: 'BreadcrumbsLayout',
                        parent_route_names: ['orders']
                    }
                }
            },
            {
                path: 'additional_files',
                name: 'order_additional_files',
                component: AdditionalFiles,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'BreadcrumbsLayout',
                    breadcrumbs: {
                        name: 'Order Additional Files',
                        layout: 'BreadcrumbsLayout',
                        parent_route_names: ['orders']
                    }
                }
            },
            {
                path: 'messages',
                name: 'order_messages',
                component: Messages,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'BreadcrumbsLayout',
                    breadcrumbs: {
                        name: 'Order Messages',
                        layout: 'BreadcrumbsLayout',
                        parent_route_names: ['orders']
                    }
                }
            },
            {
                path: 'support',
                name: 'order_support',
                component: Tickets,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'BreadcrumbsLayout',
                    breadcrumbs: {
                        name: 'Order help center',
                        layout: 'BreadcrumbsLayout',
                        parent_route_names: ['orders']
                    }
                }
            }
        ]
    }
]
