<template>
    <div>
        <!-- snack bar notification -->
        <snack-bar />
        <!-- confirm action, yes or no -->
        <confirm-modal ref="modal_confirm" />
        <!-- report a problem modal -->
        <report-modal ref="modal_report_problem" />
        <!-- page overlay -->
        <overlay />
        <!-- auth popup -->
        <confirm-auth-popup />

        <SupportMessenger />
    </div>
</template>
<script>
import SnackBar from '@/components/SnackBar.vue'
import Overlay from '@/components/modals/Overlay'
import ConfirmAuthPopup from '@/components/popups/ConfirmAuthPopup'
import ConfirmModal from '@/components/modals/ConfirmModal'
import ReportModal from '@/components/modals/ReportModal'
import SupportMessenger from '@/components/support/supportMessenger/SupportMessenger'

export default {
    name: 'GlobalModals',
    components: {
        SnackBar,
        Overlay,
        ConfirmModal,
        ReportModal,
        ConfirmAuthPopup,
        SupportMessenger
    },
    async mounted() {
        // initialize modals
        this.$nextTick(() => {
            this.$root.$confirm = this.$refs.modal_confirm.open
            this.$root.$confirm_password = this.$refs.modal_report_problem.open
        })
    }
}

</script>
