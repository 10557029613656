import Api from '@/helpers/api/index.js'

import {
    ACTION_GET_CURRENT_STEP,
    ACTION_SET_CURRENT_STEP,
    ACTION_GET_EXAM_QUESTIONS,
    ACTION_SAVE_ANSWER,
    ACTION_GET_EXAM_QUESTIONS_COUNT
} from './action-types';

import {
    SET_QUESTIONS,
    SET_STEP,
    SET_RESULT,
    SET_COUNT
} from './mutation-types'

export const actions = {
    async [ACTION_SET_CURRENT_STEP]({ commit }, payload) {
        const response = await Api.put('/api/writer/update-step-wizard', {
            step: payload.step
        })
        commit(SET_STEP, payload.step)
        return response
    },
    async [ACTION_GET_CURRENT_STEP]({ commit }) {
        const response = await Api.get('/api/writer/get-step-wizard', {}, { dialog: true }, false)
        commit(SET_STEP, response.data)
        return response
    },
    async [ACTION_GET_EXAM_QUESTIONS]({ commit }, payload) {
        const response = await Api.get('/api/exam/get-question', payload, { dialog: true }, false)
        if (response.data.status === 'next') {
            commit(SET_QUESTIONS, { ...response.data.data, questionIndex: response.data.question })
            return response
        }
        commit(SET_RESULT, response.data.data)
        return response
    },
    async [ACTION_GET_EXAM_QUESTIONS_COUNT]({ commit }) {
        const response = await Api.get('/api/exam/get-count-question')
        commit(SET_COUNT, response.data)
        return response.data
    },
    async [ACTION_SAVE_ANSWER](ctx, payload) {
        const response = await Api.post('/api/exam/save-answer', payload)
        return response
    }
}
