<template>
    <div
        v-click-outside="handleBlur"
        class="form-select form-select-country"
    >
        <fieldset class="form-select__fieldset form-select-country__fieldset">
            <legend class="form-select__legend">
                {{ label }}
            </legend>
            <div
                :class="{ 'error-border': validationError, 'success': isValid, 'empty' : !country.value }"
                class="form-select-country__block"
            >
                <span
                    v-if="country.iso"
                    id="phone-code"
                    class="phone-code"
                    @click="openListItem = !openListItem"
                >
                    <span :class="'flag-icon flag-input-icon flag-icon-' + country.iso.toLowerCase()" />
                    (+{{ country.value }})
                </span>
                <input
                    id="phone-select__input"
                    ref="code"
                    :style="{paddingLeft: country.value ? '5px' : '10px'}"
                    autocomplete="nope"
                    class="form-select__input"
                    type="text"
                    @click="openListItem = !openListItem; countryList = [...phone_codes]"
                    @focus="$event.target.select()"
                    @keyup="onInputChanged($event)"
                >
            </div>
            <!-- dropdown select -->
            <ul
                v-if="openListItem"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in countryList"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item)"
                >
                    <div :class="'flag-icon flag-icon-' + item.iso.toLowerCase()" />
                    {{ item.text }} (+{{ item.value }})
                </li>
            </ul>
            <!-- dropdown select -->
            <span class="form-input__error">{{ validationError }}</span>
        </fieldset>
    </div>
</template>
<script>

import ClickOutside from 'vue-click-outside'

import Api from '@/helpers/api/index.js'

// VUEX
import { mapPhoneFields } from '@/store/modules/phone'

export default {
    name: 'PhoneNumberSelect',
    directives: {
        ClickOutside
    },
    props: {
        value: {
            type: Object
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        isValid: {
            type: Boolean
        }
    },
    data() {
        return {
            openListItem: false,
            phone_codes: [],
            countryList: [],
            searchItemName: null,
            filteredItems: [],
            search: '',
            initValue: null,
            countryIso: '',
            prevValue: ''
        }
    },
    computed: {
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ]),
        validationError() {
            return this.errors ? this.errors : ''
        }
    },
    watch: {
        async country(value) {
            if (value) {
                try {
                    // const code = await this.getPhoneCodeByCountry(value)
                    const code = value.value
                    this.$store.commit('writer/SET_PHONE_CODE_AND_COUNTRY', {
                        phone_code: code.toString()
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        },
        isFormValid(val) {
            this.$emit('phoneValidated', val)
        }
    },
    async mounted() {
        await this.getCountries()
    },
    methods: {
        async selectItem(value) {
            this.country = value
            await this.$emit('input', value)
            this.initValue = this.value
            this.openListItem = false
            this.search = ''
            this.$refs.code.value = ''
            this.countryIso = value.iso
        },
        async getCountries() {
            const response = await Api.get('/api/data/countries')
            this.phone_codes = [...response.data]
            this.countryList = [...response.data]
        },
        handleBlur() {
            if (this.openListItem) {
                this.openListItem = false
            }
        },
        onInputChanged(event) {
            this.openListItem = true
            if (event.code === 'Backspace' && this.search.length === 0) {
                this.country = {}
                return;
            }
            this.search = event.target.value
            this.initValue = event.target.value
            if (!this.search) {
                this.countryList = this.phone_codes
                return
            }
            if (this.search.indexOf('+') === 0) {
                this.search = this.search.slice(1)
            }
            const countriesArray = this.phone_codes.filter((item) => (item.text.toLowerCase() + item.value.toString().toLowerCase()).includes(this.search.toLowerCase()))
            this.countryList = countriesArray.sort(this.sortByField('value'))
        },
        sortByField(field) {
            return (a, b) => {
                if (a[field] > b[field]) {
                    return 1
                }
                return -1
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .form-select {
        outline: none;
        border-radius: 4px 0 0 4px;
        max-height: 50px;
        width: 100%;
        &__fieldset::after {
            right: 10px;
        }
        &-country__block {
            display: flex;
            height: 48px;
            max-height: 48px;
            line-height: 48px;
            border-radius: 4px 0 0 4px;
            cursor: pointer;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid #d3d9e2 !important;
            .form-select__input {
                height: unset;
                line-height: 42px;
            }
            .form-input__input.error-border {
                border: 1px solid red !important;
            }
            &.error-border {
                border: 1px solid red !important;
            }
            &.success {
                border: 1px solid #27d91f!important;
            }
        }
        &__icon {
            position: absolute;
            top: 10px;
            left: 12px;
        }
        &__fieldset {
            position: relative;
            border: none;
            outline: none;
        }
        &__legend {
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -10px;
            background-color: white;
            border: none;
        }
        &__input {
            display: inline-block;
            border: none !important;
            outline: none;
            padding: 0 30px 0 10px;
            &:focus, &:active {
                border: none !important;
            }
            &.empty {
                padding-left: 10px;
            }
        }
        &__error {
            display: inline;
            width: auto;
            text-align: left;
            color: $error-color;
            caret-color: $error-color;
            font-size: 12px;
            min-height: 14px;
            position: relative;
            position: absolute;
            left: 13px;
            bottom: -7px;
            background: white;
        }
        &__list {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 270px;
            max-width: 285px;
            background-color: white;
            position: absolute;
            z-index: 10;
            top: 55px;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 0;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
        }
        &__list-item {
            display: flex;
            flex-direction: row;
            padding: 15px;
            background-color: #fff;
            margin-bottom: 1px;
            cursor: pointer;
            &:hover {
                background-color: lightgrey;
            }
            .flag-icon {
                margin-right: 10px;
            }
        }
        .phone-code {
            cursor: pointer;
            margin-left: 10px;
            font-size: 14px;
            display: flex;
            align-content: center;
            white-space: nowrap;
            align-items: center;
            .flag-icon {
                margin-right: 4px;
            }
        }
    }

    .flag-icon {
        background-size: contain!important;
        background-position: 50%!important;
        background-repeat: no-repeat!important;
        position: relative;
        display: inline-block;
        width: 1.33333333em;
        line-height: 1em;
    }
    .flag-icon:before {
        content: "\00a0";
    }

</style>
