const SupportForm = () => import(/* webpackChunkName: "_support-form-page" */'@/views/support/Main.vue')

export default [
    {
        path: '/support/ticket/new',
        component: SupportForm,
        name: 'support_form',
        meta: {
            requiresAuth: true
        }
    }
]
