import routesAcconutOrder from './account-order.js'

const ClientAccount = () => import('@/views/account/ClientAccountPage.vue')
const Orders = () => import('@/views/account/orders/Orders.vue')
const Profile = () => import('@/views/profile/Profile.vue')
const ProfilePaymentMethod = () => import('@/views/profile/ProfilePaymentMethod.vue')
const ProfileDocuments = () => import('@/views/profile/ProfileDocuments.vue')
const ProfilePaymentsAddMethod = () => import('@/views/profile/ProfilePaymentsAddMethod.vue')
const ProfileNickname = () => import('@/views/profile/ProfileNickname.vue')
const ProfileEmail = () => import('@/views/profile/ProfileEmail.vue')
const ProfilePassword = () => import('@/views/profile/ProfilePassword.vue')
const ProfilePhone = () => import('@/views/profile/ProfilePhone.vue')
const ProfileTwoFa = () => import('@/views/profile/ProfileTwoFa.vue')
const ProfileAddress = () => import('@/views/profile/ProfileAddress.vue')
const ProfileGeneral = () => import('@/views/profile/ProfileGeneral.vue')
const Tickets = () => import('@/views/account/TicketsPage.vue')
const Bookmarks = () => import('@/views/account/Bookmarks.vue')
const Earnings = () => import('@/views/account/CreditsPage.vue')
const DocumentsList = () => import('@/views/account/documents/DocumentsList.vue')
const FaqList = () => import('@/views/account/documents/FaqPage.vue')
const TermsOfServices = () => import('@/views/account/documents/TermsOfServices.vue')
const Privacy = () => import('@/views/account/documents/Privacy.vue')
const ReferencingAndPlagiarismManuals = () => import('@/views/account/documents/ReferencingAndPlagiarismManuals.vue')
const Payouts = () => import('@/views/account/payouts/Payouts.vue')
const Feedbacks = () => import('@/views/account/Feedbacks.vue')
const WriterAgreement = () => import('@/views/account/documents/WriterAgreement.vue')

const WriterExam = () => import('@/views/account/Exam/Index.vue')

export default [
    ...routesAcconutOrder,
    {
        // account orders pages
        path: '/cp/account',
        component: ClientAccount,
        name: 'account',
        meta: {
            requiresAuth: true
        }
    },
    {
        // account documents
        path: '/cp/documents',
        component: DocumentsList,
        name: 'documents',
        meta: {
            requiresAuth: true
        }
    },
    {
        // account documents
        path: '/cp/documents/faq',
        component: FaqList,
        name: 'faq',
        meta: {
            requiresAuth: true
        }
    },
    {
        // account documents
        path: '/cp/documents/exam',
        component: WriterExam,
        name: 'documents-exam',
        meta: {
            requiresAuth: true
        }
    },
    {
        // account documents
        path: '/cp/documents/manual',
        component: ReferencingAndPlagiarismManuals,
        name: 'manual',
        meta: {
            requiresAuth: true
        }
    },
    {
        // account documents
        path: '/cp/documents/writer-agreement',
        component: WriterAgreement,
        name: 'writer-agreement',
        meta: {
            requiresAuth: true
        }
    },
    {
        // account documents
        path: '/cp/documents/terms',
        component: TermsOfServices,
        name: 'terms'
    },
    {
        // account documents
        path: '/cp/documents/privacy',
        component: Privacy,
        name: 'privacy'
    },
    {
        // orders pages
        path: '/cp/orders',
        component: Orders,
        name: 'orders',
        meta: {
            requiresAuth: true,
            keepAlive: true
        }
    },
    { // tickets page
        path: '/cp/tickets/',
        component: Tickets,
        name: 'tickets',
        meta: {
            requiresAuth: true
        }
    },
    { // bookmarks page
        path: '/cp/bookmarks/',
        component: Bookmarks,
        name: 'bookmarks',
        meta: {
            requiresAuth: true
        }
    },
    { // feedbacks page
        path: '/cp/feedbacks/',
        component: Feedbacks,
        name: 'clients-feedbacks',
        meta: {
            requiresAuth: true
        }
    },
    { // profile page
        path: '/cp/profile',
        component: Profile,
        name: 'profile',
        meta: {
            requiresAuth: true
        }
    },
    { // profile withdrawal page
        path: '/cp/profile/payment-method',
        component: ProfilePaymentMethod,
        name: 'profile_payment-method',
        meta: {
            requiresAuth: true
        }
    },
    { // profile documents page
        path: '/cp/profile/payment-documents',
        component: ProfileDocuments,
        name: 'profile_documents',
        meta: {
            requiresAuth: true
        }
    },
    { // profile e-mail page
        path: '/cp/profile/payment-method/add',
        component: ProfilePaymentsAddMethod,
        name: 'profile_payment-method-add',
        meta: {
            requiresAuth: true
        }
    },
    { // profile e-mail page
        path: '/cp/profile/email',
        component: ProfileEmail,
        name: 'profile_email',
        meta: {
            requiresAuth: true
        }
    },
    { // profile nickname page
        path: '/cp/profile/nickname',
        component: ProfileNickname,
        name: 'profile_nickname',
        meta: {
            requiresAuth: true
        }
    },
    { // profile password page
        path: '/cp/profile/password',
        component: ProfilePassword,
        name: 'profile_password',
        meta: {
            requiresAuth: true
        }
    },
    { // profile phone page
        path: '/cp/profile/phone',
        component: ProfilePhone,
        name: 'profile_phone',
        meta: {
            requiresAuth: true
        }
    },
    { // profile 2fa page
        path: '/cp/profile/security',
        component: ProfileTwoFa,
        name: 'profile_security',
        meta: {
            requiresAuth: true
        }
    },
    { // profile address page
        path: '/cp/profile/address',
        component: ProfileAddress,
        name: 'profile_address',
        meta: {
            requiresAuth: true
        }
    },
    { // profile writers page
        path: '/cp/profile/general',
        component: ProfileGeneral,
        name: 'profile_writers',
        meta: {
            requiresAuth: true
        }
    },
    { // profile credits page
        path: '/cp/earnings',
        component: Earnings,
        name: 'earnings',
        meta: {
            requiresAuth: true
        }
    },
    { // profile credits page
        path: '/cp/payouts',
        component: Payouts,
        name: 'payouts',
        meta: {
            requiresAuth: true
        }
    }
]
