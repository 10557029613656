import {
    SET_DATA,
    DELETE_DATA_BOOKMARKS,
    CREATE_BOOKMARK_ITEM,
    SET_LOAD_MORE
} from './mutation-types.js';

export const mutations = {
    [SET_DATA](state, payload) {
        state.bookmarks = payload.reverse();
    },
    async [CREATE_BOOKMARK_ITEM](state, payload) {
        state.bookmarks = [
            ...state.bookmarks,
            payload
        ]
    },
    [DELETE_DATA_BOOKMARKS](state, { id }) {
        const index = state.bookmarks.findIndex((item) => item.id === id)
        state.bookmarks.splice(index, 1)
    },
    [SET_LOAD_MORE](state, data) {
        state.bookmarks = [
            ...state.bookmarks,
            ...data
        ]
    }
}
