export class Config {
    constructor({
        pricing = []
    } = {}) {
        this.pricing = pricing
    }
}

export function createConfig(data) {
    return Object.freeze(new Config(data));
}
