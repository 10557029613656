<template>
    <div class="clients-messanger_list">
        <loading-overlay v-if="loading" />
        <template v-else-if="!loading && list.length > 0">
            <div
                v-for="(chat) in list"
                :key="chat.id"
                class="clients-messanger_chat"
                @click="goToChat(chat.id)"
            >
                <div class="clients-messanger_chat__header">
                    <div class="clients-messanger_chat__header_top">
                        <div class="clients-messanger_chat_types">
                            <span v-if="chat.orderid">{{ chat.orderid }}</span>
                        </div>
                        <p class="clients-messanger_chat_date report">
                            <span style="white-space: nowrap">{{ chat.created_at | moment_from }}</span>
                        </p>
                    </div>
                    <b class="clients-messanger_chat_subject">«{{ chat.title }}»</b>
                </div>
                <div class="clients-messanger_chat__body">
                    <p class="clients-messanger_chat__body-last-message">
                        <avatar
                            :avatar-url="chat.last_message.author.avatar_url"
                            :avatar-fallback="chat.last_message.author.avatar_fallback"
                            :firstname="chat.last_message.author.firstname"
                            :lastname="chat.last_message.author.lastname"
                            :nickname="chat.last_message.author.nickname"
                            :user="chat.last_message.author.type"
                        />
                        <span>
                            «{{ chat.last_message.details }}»
                        </span>
                    </p>
                    <span class="clients-messanger_chat__bell">
                        <span
                            v-if="chat.counters.unread_messages_count > 0"
                            class="bubble--unread"
                        >
                            {{ chat.counters.unread_messages_count }}
                        </span>
                    </span>
                </div>
            </div>
        </template>
        <!-- <no-result v-else /> -->
        <not-found
            v-else-if="!loading && !list.length"
            text="No chats with clients"
            desc="No chats with clients yet"
        />
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'
import Avatar from '@/components/common/Avatar';
import LoadingOverlay from '@/components/LoadingOverlay';
// import NoResult from '@/components/common/NoResult'
import NotFound from '../../NotFound.vue'

export default {
    components: {
        // NoResult,
        Avatar,
        NotFound,
        LoadingOverlay
    },
    mixins: [
        filtersMixin
    ],
    props: {
        list: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        goToChat(id) {
            this.$emit('goToChat', id)
        },
        getLastMessageOwner(type) {
            return type.toLowerCase()
        }
    }
}
</script>
<style lang="scss">
.clients-messanger{
    &_list {
        height: 100%;

        .not-found {
            font-size: 16px;
            line-height: 1.4;
            height: 100%;
            justify-content: center;
            &_img{
                width: 50px;
                height: 50px;
                @media (max-width: 992px){
                    width: 30px;
                    height: 30px;
                }
            }
            &-top{
                font-size: 24px;
                line-height: 1.4;
                @media (max-width: 992px){
                    font-size: 18px;
                    line-height: 1.4;
                }
            }
        }
    }
    &_chat{
        padding: 10px 10px 20px 10px;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #e7ecf4;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        &:hover {
            border-radius: 0 0 10px 10px;
            background: rgba($main-color, 0.1);
            border-bottom: 1px solid transparent;
            border: 1px solid $main-color;
            box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
            .support-messenger_chat_types span{
                background: rgba($main-color, 0.3);
            }
            .support-messenger_chat_subject {
                text-decoration: underline;
            }
        }
        &__header{
            &_top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
        &_types{
            display: flex;
            flex-wrap: wrap;
            span {
                margin-right:10px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: max-content;
                padding: 5px;
                border-radius: 5px;
                background-color: rgba($main-color, 0.1);
                color: rgb(40, 64, 103);
                position: relative;
                &::after {
                    content: ">";
                    margin-right: 5px;
                    position: absolute;
                    right: -15px;
                }
                &:last-child{
                    &::after {
                        display: none;
                    }
                }
            }
        }
        &__body{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px;
        }
        &__body-last-message{
            display: flex;
            align-items: center;
            span:not(.initials) {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 13px;
                width: calc(100% - 20px);
                padding-left: 10px;
            }
            .avatar-img{
                width: 22px;
                height: 22px;
                border: 1px solid $main-color;
                .initials {
                    font-size: 100%;
                    text-align: center;
                }
            }
            .avatar-img .default-avatar {
                width: 12px;
                background-color: $main-color;
            }
        }
        &_date{
            display: flex;
            align-items: center;
            font-size: 12px;
        }
        &_date_tool {
            font-size: 10px !important;
            left: calc(50% - 170px) !important;
            z-index: 9 !important;
        }
        &_subject {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.5;
        }
        &__bell{
            position: relative;
            margin-left: 10px;
            svg {
                width: 26px;
                height: 26px;
            }
        }
    }
}
</style>
