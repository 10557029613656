export const setGuestTicket = (ticket) => {
    const data = localStorage.getItem('guest_ticket')
    const parsedData = data ? JSON.parse(data) : []
    parsedData.push(JSON.stringify(ticket))
    localStorage.setItem('guest_ticket', JSON.stringify(parsedData))
}
export const getGuestTicketFromStorage = () => {
    const data = localStorage.getItem('guest_ticket')
    const parsedData = JSON.parse(data)
    return parsedData?.map((item) => JSON.parse(item)) || []
}
export const removeGuestTicketFromStorage = (indexes) => {
    const data = localStorage.getItem('guest_ticket');
    const parsedData = JSON.parse(data);
    indexes.forEach((index) => {
        parsedData.splice(index, 1, false)
    });

    localStorage.setItem('guest_ticket', JSON.stringify(parsedData?.filter((item) => Boolean(item)) || []))
}

export const checkDiff = (list1, list2) => {
    const list1Dates = list1.map((item) => item.messages[item.messages.length - 1].created_at)
    const list2Dates = list2.map((item) => item.messages[item.messages.length - 1].created_at)
    return list1Dates.join('') !== list2Dates.join('')
}
