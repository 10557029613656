import Api from '@/helpers/api/index.js'
import Auth from '@/helpers/auth/index.js'

import {
    ACTION_LOGIN_WRITER,
    ACTION_LOGOUT_WRITER,
    ACTION_CREATE_WRITER,
    ACTION_UPDATE_WRITER_DATA,
    ACTION_WRITER_CHECK_EMAIL,
    ACTION_WRITER_UPDATE_EMAIL,
    ACTION_WRITER_UPDATE_PASSWORD,
    ACTION_WRITER_UPDATE_PHONE,
    ACTION_WRITER_UPDATE_ADDRESS,
    ACTION_WRITER_CHECK_PHONE_EXISTS,
    ACTION_WRITER_UPDATE_SETTINGS,
    ACTION_WRITER_RESET_PASSWORD_SEND_EMAIL,
    ACTION_WRITER_RESET_PASSWORD_SET_NEW,
    ACTION_UPDATE_CONTACT_DETAILS,
    ACTION_UPDATE_PAYMENT_DETAILS,
    ACTION_UPDATE_GENERAL_INFO,
    ACTION_REFRESH_TOKEN,
    ACTION_RESET_ALL_MODULES,
    LOGIN_RESET_MODULES,
    ACTION_WRITER_CHECK_NIKNAME,
    ACTION_WRITER_UPDATE_NICKNAME,
    ACTION_WRITER_UPDATE_AVATAR,
    ACTION_WRITER_SOCIAL_LOGIN_GET_URL,
    ACTION_WRITER_SOCIAL_LOGIN_VERIFY,
    ACTION_WRITER_TOUCH
} from './action-types.js'

import {
    LOGIN_WRITER,
    UPDATE_WRITER_DATA,
    SET_WRITER_TIMEZONE,
    UPDATE_ADDRESS,
    UPDATE_WRITER_NICKNAME,
    UPDATE_WRITER_AVATAR,
    SET_TOUCH_INTERVAL,
    CLEAR_TOUCH_INTERVAL,
    LOGOUT_WRITER,
    UPDATE_WRITER_PHONE_DETAILS,
    CLEAR_FORM
} from './mutation-types.js'

export const actions = {
    async [ACTION_LOGIN_WRITER]({ commit }, payload) {
        const response = await Api.post('/api/auth/login', payload)
        const { token_data, writer } = response.data
        Auth.login(token_data)
        commit(LOGIN_WRITER, { writer })
    },
    async [ACTION_WRITER_TOUCH]({ commit, getters }) {
        if (getters.getterIsAuth) {
            const touchInterval = setInterval(async () => {
                await Api.get('/api/auth/touch')
            }, 1000 * 60 * (process.env.VUE_APP_TOUCH_DELAY_MINUTES || 1));
            commit(SET_TOUCH_INTERVAL, touchInterval)
        } else {
            commit(CLEAR_TOUCH_INTERVAL)
        }
    },
    async [LOGIN_RESET_MODULES]({ commit }) {
        // commit('checkout/RESET', null, { root: true })
        commit('phone/RESET', null, { root: true })
        // commit('support/RESET', null, { root: true })
    },
    async [ACTION_UPDATE_WRITER_DATA]({ commit }) {
        // This action is fired after a successful login.
        // First we get the data (client stats),
        const response = await Api.get('/api/writer/fetch-data', {}, { dialog: true }, false)
        // update client's data in store
        commit(UPDATE_WRITER_DATA, response.data)
    },
    async [ACTION_REFRESH_TOKEN]({ commit }, payload) {
        return Api.post('/api/auth/refresh-token', payload)
            .then(async (response) => {
                const token = {
                    token: response.data.data.access_token,
                    ...response.data.data
                }
                Auth.login(token)
                commit(LOGIN_WRITER, token)
            })
    },
    async [ACTION_LOGOUT_WRITER]({ commit }) {
        await Api.post('/api/auth/logout')
        await Auth.logoutVuex()
        await Auth.logout()
        commit(LOGOUT_WRITER)
        commit(CLEAR_FORM)
    },
    [ACTION_RESET_ALL_MODULES]({ commit }) {
        // Reset all modules
        commit('writer/RESET', null, { root: true })
        commit('wizard/RESET', null, { root: true })
        commit('phone/RESET', null, { root: true })
    },
    async [ACTION_WRITER_CHECK_EMAIL]({ rootState }, payload) {
        const options = {
            dialog: false
        }
        if (!rootState.writer.auth || payload.isSetting) {
            const response = await Api.post('/api/writer/validation/email-exists', payload, options)
            return response
        }
        return {}
    },
    async [ACTION_WRITER_CHECK_NIKNAME]({ rootState }, payload) {
        const options = {
            dialog: false
        }
        if (!rootState.writer.auth) {
            const response = await Api.post('/api/writer/validation/nickname-exists', payload, options)
            return response
        }
        return {}
    },
    async [ACTION_CREATE_WRITER]({ commit, state, rootState }) {
        commit(SET_WRITER_TIMEZONE)
        const {
            email,
            nickname,
            firstname,
            lastname,
            password
        } = rootState.writer.writer_data
        const {
            phone_number, country
        } = rootState.phone.form_data
        const form_data = {
            nickname,
            firstname,
            lastname,
            email,
            password,
            phone: phone_number,
            phone_code: `${country.value}`,
            country: `${country.iso}`,
            timezone: state.timezone
        }
        const data = JSON.parse(JSON.stringify(form_data));
        await Api.post('/api/auth/register', data)
    },
    async [ACTION_UPDATE_CONTACT_DETAILS](ctx, payload) {
        const response = await Api.put('/api/writer/update', payload)
        return response
    },
    async [ACTION_UPDATE_PAYMENT_DETAILS](ctx, payload) {
        const response = await Api.put('/api/writer/add-payments', payload)
        return response
    },
    async [ACTION_UPDATE_GENERAL_INFO](ctx, payload) {
        const response = await Api.put('/api/writer/application/update', payload)
        return response
    },
    async [ACTION_WRITER_UPDATE_EMAIL](ctx, payload) {
        const response = await Api.put('/api/writer/update', payload)
        return response
    },
    async [ACTION_WRITER_UPDATE_ADDRESS]({ commit }, payload) {
        await Api.put('/api/writer/update', payload)
        commit(UPDATE_ADDRESS, payload)
    },
    [ACTION_WRITER_CHECK_PHONE_EXISTS](ctx, payload) {
        // this action is used in validation, a promise is needed
        return new Promise((resolve, reject) => {
            Api.post('/api/writer/validation/phone-exists', payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async [ACTION_WRITER_UPDATE_PHONE]({ commit }, payload) {
        await Api.put('/api/writer/update', payload)
        commit(UPDATE_WRITER_PHONE_DETAILS, payload)
    },
    async [ACTION_WRITER_UPDATE_PASSWORD](ctx, payload) {
        await Api.put('/api/writer/update', payload)
    },
    async [ACTION_WRITER_UPDATE_SETTINGS](ctx, payload) {
        await Api.put('/api/writer/update-settings', payload)
    },
    async [ACTION_WRITER_RESET_PASSWORD_SEND_EMAIL](ctx, payload) {
        await Api.post('/api/auth/forgot-password/send-email', payload)
    },
    async [ACTION_WRITER_RESET_PASSWORD_SET_NEW](ctx, payload) {
        await Api.put('/api/auth/forgot-password/set-new-password', payload)
    },
    async [ACTION_WRITER_UPDATE_NICKNAME]({ commit }, payload) {
        await Api.put('/api/writer/update', payload)
        commit(UPDATE_WRITER_NICKNAME, payload)
    },
    async [ACTION_WRITER_UPDATE_AVATAR]({ commit }, { formData, callback }) {
        const { data } = await Api.post('/api/writer/update-avatar', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: callback
        })
        commit(UPDATE_WRITER_AVATAR, data)
    },
    async [ACTION_WRITER_SOCIAL_LOGIN_GET_URL](ctx, payload) {
        return Api.get(`/api/auth/oauth/${payload.service}/get-redirect-url`)
    },
    async [ACTION_WRITER_SOCIAL_LOGIN_VERIFY]({ commit }, payload) {
        return new Promise((resolve) => {
            Api.post(`/api/auth/oauth/${payload.service}-callback`, {
                code: payload.code
            })
                .then(async (response) => {
                    if (response.data.status === 401) {
                        resolve(response.data)
                    } else {
                        const { token, writer } = response.data
                        Auth.login(token)
                        commit(LOGIN_WRITER, { token, writer })
                    }
                })
                .catch((error) => {
                    throw new Error(error)
                })
        })
    },
    [SET_TOUCH_INTERVAL](state, payload) {
        state.touchInterval = payload
    },
    [CLEAR_TOUCH_INTERVAL](state) {
        clearInterval(state.touchInterval)
    }
}
