import { updateField } from 'vuex-map-fields';
import { cloneDeep } from 'lodash'
import { initialState } from './state.js'

import {
    SET_QUESTIONS,
    ADD_ANSWER,
    NEXT_QUSTION,
    REPEAT_EXAM,
    SET_RESULT,
    SET_STEP,
    SET_COUNT,
    RESET
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_QUESTIONS](state, payload) {
        state.wizard.exam.questions = payload
    },
    [SET_RESULT](state, payload) {
        state.wizard.exam.result = payload
    },
    [SET_COUNT](state, count) {
        state.wizard.exam.count = +count
    },
    [ADD_ANSWER](state, payload) {
        state.wizard.exam.answers.push(payload)
    },
    [NEXT_QUSTION](state) {
        state.wizard.exam.question_id += 1
    },
    [REPEAT_EXAM](state) {
        state.wizard.exam.question_id = 0
        state.wizard.exam.answers = []
        state.wizard.exam.status = 'begin'
    },
    [SET_STEP](state, step) {
        state.wizard.steps.currentStep = step
    },
    [RESET](state) {
        Object.assign(state, cloneDeep(initialState))
    }
}
