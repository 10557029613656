<template>
    <div class="message-form">
        <div class="message-form_typing">
            <slot name="typing" />
        </div>
        <form-textarea
            v-model="message"
            legend="Message"
            type="text"
            name="message"
            class="message-form_textfield"
            :class="{'message-form_textfield--large':showResolveBtn}"
            :field.sync="message"
            :errors="validationMessage($v.message)"
            :disabled="isLoading"
            @input.native="$v.message.$touch()"
            @blur.native="$v.message.$touch()"
            @keydown.native="keyListener($event)"
            @focus="$emit('typing', true)"
            @blur="$emit('typing', false)"
        />
        <div
            class="message-form_actions"
            :class="{'message-form_actions--large':showResolveBtn}"
        >
            <custom-button
                class="btn-base btn-main"
                :loading="isLoading"
                :disabled="isLoading"
                @on-btn-click="sendMessage()"
            >
                <replyIcon v-if="!isLoading" />
            </custom-button>
            <custom-button
                v-if="showResolveBtn"
                class="btn-base btn-border"
                @on-btn-click="openConfirmModal('Close ticket', 'Are you really want to close the ticket ?')"
            >
                My issue is resolved. Close ticket
            </custom-button>
        </div>

        <confirm-modal ref="confirm-modal" />
    </div>
</template>

<script>
// Validation
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages';
import { required } from 'vuelidate/lib/validators';
import { formMessages } from '@/validation/support/Messages';

import replyIcon from '@/components/icons/reply.vue'
import ConfirmModal from '@/components/modals/ConfirmModal';

export default {
    components: {
        replyIcon,
        ConfirmModal
    },
    mixins: [validationMixin],
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        clearForm: {
            type: Boolean,
            required: false,
            default: true
        },
        showResolveBtn: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    validations: {
        message: { required }
    },
    data() {
        return {
            message: ''
        }
    },
    watch: {
        clearForm(newV) {
            if (newV === true) {
                this.message = ''
                this.$emit('formCleared', true)
            }
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        keyListener(e) {
            if (e.ctrlKey && e.keyCode === 13) {
                this.message += '\n'
                return
            }
            if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13 && !this.isLoading) {
                e.preventDefault();
                this.sendMessage()
            }
        },
        sendMessage() {
            this.$emit('sendMessage', { message: this.message })
        },
        async openConfirmModal(title, message) {
            const status = await this.$refs['confirm-modal'].open(title, message)
            if (status) {
                this.$emit('closeTicket')
            }
        }
    }
}
</script>

<style lang="scss">
    .message-form{
        display: flex;
        flex-wrap: wrap;
        &_typing{
            width: 100%;
            margin-bottom: 15px;
            height: 14px;
            display: flex;
            align-items: center;
            & > p {
                font-size: 14px;
                line-height: 1;
                font-style: italic;
                color: #a8aaae;
                margin-right: 5px;
            }
        }
        &_textfield{
            width: calc(100% - 90px) !important;
            height: 75px;
            &--large {
                width: calc(100% - 320px) !important;

                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            textarea {
                height: 100%;
                min-height: 100%;
                max-height: 100%;
            }
        }
        &_actions{
            margin-left: 15px;
            display: flex;
            width: 75px;
            &--large {
                width: 305px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 20px;
                    .btn-border {
                        width: 100%;
                    }
                }
            }
            .btn-base {
                height: 75px;
                &:last-child {
                    &:not(:first-child) {
                        margin-left: 15px;
                    }
                }
            }
            .btn-main {
                width: 75px;
                height: 75px;
            }
        }
    }
</style>
