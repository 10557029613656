const WizardAddress = () => import('@/views/Wizard/WizardAddress')
const WizardAgreement = () => import('@/views/Wizard/WizardAgreement')
const WizardFaq = () => import('@/views/Wizard/WizardFaq')
const WizardExam = () => import('@/views/Wizard/WizardExam')
const WizardDocuments = () => import('@/views/Wizard/WizardDocuments')
const WizardGeneral = () => import('@/views/Wizard/WizardGeneral')
const WizardPayment = () => import('@/views/Wizard/WizardPayment')
const Wizard = () => import('@/views/Wizard')

export default [
    {
        name: 'wizard',
        path: '/wizard',
        component: Wizard,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/wizard/wizard-address',
                name: 'wizard-address',
                component: WizardAddress,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/wizard/wizard-general',
                name: 'wizard-general',
                component: WizardGeneral,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/wizard/wizard-document',
                name: 'wizard-document',
                component: WizardDocuments,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/wizard/wizard-agreement',
                name: 'wizard-agreement',
                component: WizardAgreement,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/wizard/wizard-payment',
                name: 'wizard-payment',
                component: WizardPayment,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/wizard/wizard-faq',
                name: 'wizard-faq',
                component: WizardFaq,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/wizard/wizard-exam',
                name: 'wizard-exam',
                component: WizardExam,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    }
]
