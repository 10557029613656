import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/'

export const getClientChatMessages = async (payload) => {
    const { data } = await Api.get('/api/chat-client-writer/list-messages', removeEmptyParamsFromRequest({ ...payload }), false, false)
    return data
}

export const sendMessage = async (payload) => {
    const { data } = await Api.put('/api/chat-client-writer/create-message', removeEmptyParamsFromRequest({ ...payload }))
    return data
}

export const readChat = async (payload) => {
    await Api.post('/api/chat-client-writer/mark-as-read', removeEmptyParamsFromRequest({ ...payload }))
}

export const getClientsChatsList = async (payload) => {
    const { data } = await Api.get('/api/chat-client-writer/list-chats', removeEmptyParamsFromRequest({ ...payload }), false, false)
    return data
}
