import { updateField } from 'vuex-map-fields';

import {
    SET_EXAM_SESSION,
    SET_EXAM_QUESTION,
    SET_EXAM_STATUS,
    SET_SAVE_ANSWER_LOADER,
    SET_GET_QUESTION_LOADER,
    SET_COUNTDOWN,
    SET_EXAM_STATUS_FROM_API
} from './mutation-types'

export const mutations = {
    updateField,
    [SET_EXAM_SESSION](state, payload) {
        state.examStatus = 'in_progress'
        state.token = payload.token
        state.created_at = payload.created_at
        state.expires_at = payload.expires_at
        state.questions_total = payload.questions_total
        state.questions_left = payload.questions_left
    },
    [SET_EXAM_QUESTION](state, payload) {
        state.question = { ...payload.question }
        state.answers = [...payload.answers]
        state.questions_total = payload.questions_total
        state.questions_left = payload.questions_left
    },
    [SET_EXAM_STATUS](state, payload) {
        state.examStatus = payload
    },
    [SET_SAVE_ANSWER_LOADER](state, payload) {
        state.answerLoading = payload
    },
    [SET_GET_QUESTION_LOADER](state, payload) {
        state.questionLoading = payload
    },
    [SET_COUNTDOWN](state, payload) {
        state.countdown = payload
    },
    [SET_EXAM_STATUS_FROM_API](state, payload) {
        const { code } = payload
        if (code === 1) {
            state.examStatus = 'finish'
        } else if (code === 2) {
            state.examStatus = 'in_progress'
        } else {
            state.examStatus = 'start'
        }
    }
}
