export const ACTION_LOGIN_WRITER = 'ACTION_LOGIN_WRITER'

export const ACTION_LOGOUT_WRITER = 'ACTION_LOGOUT_WRITER'

export const ACTION_CREATE_WRITER = 'ACTION_CREATE_WRITER'

export const ACTION_UPDATE_WRITER_DATA = 'ACTION_UPDATE_WRITER_DATA'

export const ACTION_WRITER_UPDATE_SETTINGS = 'ACTION_WRITER_UPDATE_SETTINGS'

export const ACTION_WRITER_CHECK_EMAIL = 'ACTION_WRITER_CHECK_EMAIL'

export const ACTION_REFRESH_TOKEN = 'ACTION_REFRESH_TOKEN'

export const ACTION_RESET_ALL_MODULES = 'ACTION_RESET_ALL_MODULES'

export const ACTION_WRITER_UPDATE_EMAIL = 'ACTION_WRITER_UPDATE_EMAIL'

export const ACTION_WRITER_UPDATE_PASSWORD = 'ACTION_WRITER_UPDATE_PASSWORD'

export const ACTION_WRITER_UPDATE_PHONE = 'ACTION_WRITER_UPDATE_PHONE'

export const ACTION_WRITER_CHECK_PHONE_EXISTS = 'ACTION_WRITER_CHECK_PHONE_EXISTS'

export const ACTION_WRITER_UPDATE_ADDRESS = 'ACTION_WRITER_UPDATE_ADDRESS'

export const ACTION_WRITER_RESET_PASSWORD_SEND_EMAIL = 'ACTION_WRITER_RESET_PASSWORD_SEND_EMAIL'

export const ACTION_WRITER_RESET_PASSWORD_SET_NEW = 'ACTION_WRITER_RESET_PASSWORD_SET_NEW'

export const LOGIN_RESET_MODULES = 'LOGIN_RESET_MODULES'

export const ACTION_UPDATE_CONTACT_DETAILS = 'ACTION_UPDATE_CONTACT_DETAILS'
export const ACTION_UPDATE_PAYMENT_DETAILS = 'ACTION_UPDATE_PAYMENT_DETAILS'
export const ACTION_UPDATE_GENERAL_INFO = 'ACTION_UPDATE_GENERAL_INFO'
export const ACTION_WRITER_CHECK_NIKNAME = 'ACTION_WRITER_CHECK_NIKNAME'
export const ACTION_WRITER_UPDATE_NICKNAME = 'ACTION_WRITER_UPDATE_NICKNAME'
export const ACTION_WRITER_UPDATE_AVATAR = 'ACTION_WRITER_UPDATE_AVATAR'

export const ACTION_WRITER_SOCIAL_LOGIN_GET_URL = 'ACTION_WRITER_SOCIAL_LOGIN_GET_URL'

export const ACTION_WRITER_SOCIAL_LOGIN_VERIFY = 'ACTION_WRITER_SOCIAL_LOGIN_VERIFY'

export const ACTION_WRITER_TOUCH = 'ACTION_WRITER_TOUCH'
