<template>
    <div
        class="global_update-modal__dialog"
    >
        <span
            class="global_update-modal__close"
            @click="close()"
        />
        <section
            class="global_update-modal__content"
            @click.prevent="submit()"
        >
            <AlertCircleOutline
                size="20"
                class="global_update-modal__icon-alert"
            />
            <span>Please verify your phone number.</span>
            <open-in-new
                size="18"
                class="global_update-modal__icon-link"
            />
        </section>
    </div>
</template>

<script>
import AlertCircleOutline from 'mdi-vue/AlertCircle'
import OpenInNew from 'mdi-vue/OpenInNew'

export default {
    name: 'ConfirmModal',
    components: {
        AlertCircleOutline,
        OpenInNew
    },
    methods: {
        close() {
            this.$emit('close')
        },
        async submit() {
            this.close()
            this.$emit('proceed')
        }
    }
}
</script>
