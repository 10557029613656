import { cloneDeep } from 'lodash'

import { updateField } from 'vuex-map-fields'
import { SchemaWriter } from '@/models/SchemaWriter.js'

import {
    RESET,
    CLEAR_FORM,
    LOGIN_WRITER,
    LOGOUT_WRITER,
    UPDATE_FORM_DATA,
    UPDATE_WRITER_DATA,
    SET_WRITER_TIMEZONE,
    SET_PHONE_CODE_AND_COUNTRY,
    UPDATE_WRITER_EMAIL,
    UPDATE_WRITER_PHONE_DETAILS,
    UPDATE_ADDRESS,
    UPDATE_WRITER_AVATAR,
    UPDATE_WRITER_NICKNAME,
    SET_TWOFA_STATUS,
    SET_TOUCH_INTERVAL,
    CLEAR_TOUCH_INTERVAL
} from './mutation-types.js'

import { initialState } from './state.js'

export const mutations = {
    updateField,
    [RESET](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [UPDATE_WRITER_EMAIL](state, payload) {
        state.writer_data.email = payload
    },
    [UPDATE_WRITER_PHONE_DETAILS](state, { phone, phone_code }) {
        state.writer_data.phone = phone
        state.writer_data.phone_code = phone_code
    },
    [LOGIN_WRITER](state, payload) {
        const { writer } = payload
        state.auth = true
        if (writer) {
            state.writer_data = new SchemaWriter(writer)
        }
    },
    [LOGOUT_WRITER](state) {
        Object.assign(state, cloneDeep(initialState))
        state.auth = false
    },
    [UPDATE_FORM_DATA](state, payload) {
        const object = JSON.parse(JSON.stringify(payload))
        state.form_data = object
    },
    [UPDATE_WRITER_DATA](state, payload) {
        state.writer_data = { ...state.writer_data, ...payload }
    },
    [CLEAR_FORM](state) {
        state.writer_data = new SchemaWriter()
    },
    [SET_WRITER_TIMEZONE](state) {
        state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    [UPDATE_ADDRESS](state, payload) {
        state.writer_data = {
            ...state.writer_data,
            ...payload
        }
    },
    [SET_PHONE_CODE_AND_COUNTRY](state, payload) {
        state.writer_data.phone_code = payload.phone_code
        state.country = payload.country
    },
    [UPDATE_WRITER_AVATAR](state, payload) {
        state.writer_data.avatar_url = payload
    },
    [UPDATE_WRITER_NICKNAME](state, { nickname }) {
        state.writer_data.nickname = nickname
    },
    [SET_TWOFA_STATUS](state, payload) {
        state.writer_data.twofa = payload
    },
    [SET_TOUCH_INTERVAL](state, payload) {
        state.touchInterval = payload
    },
    [CLEAR_TOUCH_INTERVAL](state) {
        clearInterval(state.touchInterval)
    }
};
