<template>
    <fieldset class="form-textarea__fieldset">
        <legend
            class="form-textarea__legend"
            :class="{ 'error-font-color': validationError }"
        >
            {{ legend }}
        </legend>
        <component
            :is="prependIcon"
            class="form-textarea__prepend-icon"
        />
        <textarea
            class="form-textarea__input"
            :class="{ 'error-border': validationError, 'success' : value.length >= 50 }"
            :type="type"
            :name="name"
            rows="1"
            :value="field"
            :placeholder="placeholder"
            @input="onChange($event)"
            @blur="blur($event)"
            @focus="focus($event)"
        />
        <span @click="test">
            <component
                :is="appendIcon"
                class="form-textarea__append-icon"
            />
        </span>

        <div
            v-if="validationError"
            class="form-textarea__error"
        >
            {{ validationError }}
        </div>
        <div class="form-textarea__length">
            {{ value.length }}
        </div>
    </fieldset>
</template>

<script>
import EmailOutline from 'mdi-vue/EmailOutline.vue';
import Lock from 'mdi-vue/Lock.vue';
import Account from 'mdi-vue/Account.vue';
import Eye from 'mdi-vue/Eye.vue';
import EyeOff from 'mdi-vue/EyeOff.vue';

export default {
    name: 'FormTextarea',
    components: {
        EmailOutlineIcon: EmailOutline,
        LockIcon: Lock,
        AccountIcon: Account,
        EyeIcon: Eye,
        EyeOffIcon: EyeOff
    },
    props: {
        value: {
            type: String
        },
        prependIcon: {
            type: String
        },
        appendIcon: {
            type: String
        },
        legend: {
            type: String
        },
        type: {
            type: String
        },
        name: {
            type: String
        },
        field: {
            type: String
        },
        errors: {
            type: String
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            val: ''
        };
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event.target.value);
        },
        blur(event) {
            this.$emit('blur', event.target.value)
        },
        focus(event) {
            this.$emit('focus', event.target.value)
        },
        test() {
            this.$emit('clicked');
        }
    }
};
</script>

<style lang="scss">
.form-textarea {
    &__fieldset {
        border: none !important;
        border-radius: $input-border-radius;
        padding: 0;
        position: relative;
        width: 100%;
        textarea {
            font-family: $font-family-base;
            font-size: 13px;
            border: $input-border;
            user-select: text !important;
            background: transparent;
            &:focus {
                border: $input-border-focus;
            }
            &.error-border {
                border: $input-border-error;
            }
            &.success {
                border: $input-border-success;
            }
        }
    }
    &__prepend-icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
    }
    &__append-icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0.54);
    }
    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
        &.error-font-color {
            color: $error-color !important;
            caret-color: $error-color !important;
        }
    }
    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding: 10px 40px 10px 10px;
        width: 100%;
        font-size: 14px;
        user-select: text;
        line-height: 2em;
        border: none;
        outline: none;
        border-radius: $input-border-radius;
        resize: vertical;
        min-height: 100px;
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -3px;
        background: white;
        padding: 0 3px;
    }
    &__length {
        display: inline;
        width: auto;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
        caret-color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        right: 20px;
        bottom: -4px;
        background: white;
        padding: 0 3px;
    }
}
.error-border {
    border: $input-border-error;
}
.success-border {
    border: $input-border-success;
}

.chat_actions .form-textarea__input {
    min-height: 66px;
    padding: 10px 40px 0 10px;
}

</style>
