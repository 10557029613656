<template>
    <div class="notifier">
        <transition-group name="list">
            <div
                v-for="item in list"
                :key="item.render_id"
                class="notifier_item"
                :class="`notifier_item--${item.type}`"
                @click="goToDetails(item.id)"
            >
                <component
                    :is="getIconByType(item.type)"
                    class="notifier_item__icon"
                />

                <div class="notifier_item__text">
                    <p class="notifier_item__title">
                        {{ item.title }}
                    </p>
                    <p
                        v-if="item.description"
                    >
                        «{{ item.description }}»
                    </p>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import BellOutline from 'mdi-vue/BellOutline.vue'
import Check from 'mdi-vue/Check.vue'
import AlertBoxOutline from 'mdi-vue/AlertBoxOutline.vue'
import AlertCircleOutline from 'mdi-vue/AlertCircleOutline.vue'
import ChangeTitle from '@/helpers/changeTitle.js'

const file = require('@/assets/sounds/correct-answer.wav')

export default {
    components: {
        BellOutline,
        Check,
        AlertBoxOutline,
        AlertCircleOutline
    },
    data() {
        return {
            list: [],
            currId: 0
        }
    },
    created() {
        this.$socket.on('snackbar:create', (data) => {
            this.list = [...this.list, { ...data, render_id: this.currId }]
            if (data.openMessenger) {
                if (data.id.includes('TW-')) { eventBus.$emit('openMessenger', data.id); }
            }
            this.currId += 1
            setTimeout(() => {
                this.list.shift()
            }, 10000);
            this.playSound()
            this.titleChange()
        })
    },
    methods: {
        getIconByType(type) {
            switch (type) {
            case 'default':
                return 'bell-outline'
            case 'success':
                return 'check'
            case 'warning':
                return 'alert-box-outline'
            case 'error':
                return 'alert-circle-outline'

            default:
                return 'lightbulb-alert-outline'
            }
        },
        goToDetails(id) {
            if (!id) return
            const strId = `${id}`
            const isOrderId = strId.includes('S-')
            const isTicket = strId.includes('TW-')
            if (isOrderId) {
                this.$router.push({
                    name: 'details',
                    params: {
                        strId
                    }
                })
                return
            }
            if (isTicket) {
                this.$router.push({
                    name: 'tickets',
                    query: {
                        ticketid: strId
                    }
                })
                return
            }
            eventBus.$emit('openMessenger', id, 'client-chat');
        },
        playSound() {
            const snd = new Audio(file);
            snd.play();
        },
        titleChange() {
            ChangeTitle.start('New message 🔔')
        }
    }
}
</script>

<style lang="scss">
    .notifier{
        position: fixed;
        left: 40px;
        bottom: 40px;
        display: flex;
        flex-direction: column;
        width: 300px;
        &_item {
            width: 100%;
            padding: 10px 20px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            cursor: pointer;
            transition: .3s background;
            background: #fff;
            &__icon{
                margin-right: 12px;
            }
            &__text{
                display: flex;
                flex-direction: column;
            }
            &__title{
                font-weight: bold;
                margin-bottom: 6px;
            }
            &--default {
                background-color: #cce5ff;
                color: #004085;
                & > i {
                    color: #004085;
                }
            }
            &--success {
                color: #155724;
                background-color: #d4edda;
                & > i {
                    color: #155724;
                }
            }
            &--warning {
                color: #856404;
                background-color: #fff3cd;
                & > i {
                    color: #856404;
                }
            }
            &--error {
                color: #721c24;
                background-color: #f8d7da;
                & > i {
                    color: #721c24;
                }
            }
        }
    }
    .list-enter-active, .list-leave-active {
        transition: all .3s;
    }
    .list-enter, .list-leave-to{
        opacity: 0;
        transform: translateY(30px);
    }
</style>
