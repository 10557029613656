import Auth from '@/helpers/auth/'
import { SchemaWriter } from '@/models/SchemaWriter.js'

export const initialState = {
    auth: Auth.isLogged(),
    writer_data: new SchemaWriter(),
    touchInterval: null
}

export const state = initialState
