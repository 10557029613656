import Api from '@/helpers/api/index.js'

import {
    GET_PRICING,
    GET_LIST_WRITERS,
    GET_REFERENCE_ORDERS,
    GET_LIST_NOTIFICATIONS,
    READ_NOTIFICATIONS
} from './action-types.js'

import {
    SET_PRICING,
    SET_NOTIFICATIONS
} from './mutation-types.js'

export const actions = {
    async [GET_PRICING]({ commit }, payload) {
        return new Promise((resolve) => {
            Api.get('/api/data/order', payload)
                .then((response) => {
                    commit(SET_PRICING, response.data)
                    resolve(response.data)
                })
        })
    },
    async [GET_LIST_WRITERS]() {
        const response = await Api.get('/api/writer/get-list')
        return response.data
    },
    async [GET_REFERENCE_ORDERS]() {
        const response = await Api.post('/api/cp/order/list-orders-reference-component')
        return response.data
    },
    async [GET_LIST_NOTIFICATIONS]({ commit }) {
        const response = await Api.get('/api/alert/list')
        commit(SET_NOTIFICATIONS, response.data)
    },
    async [READ_NOTIFICATIONS](ctx, payload) {
        await Api.put('/api/cp/notification/read-messages', { checked_items: payload })
    }
};
