import routesAuth from './routes/auth.js'
import routesBase from './routes/base.js'
import routesAccount from './routes/account.js'
import routesWizard from './routes/wizard'
import routesSupport from './routes/support.js'

export const routes = [
    ...routesAuth,
    ...routesAccount,
    ...routesBase,
    ...routesWizard,
    ...routesSupport
]
