import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    getQuestionById(state) {
        return state.wizard.exam.questions
        // const question = state.wizard.exam.questions.find((item, id) => id === state.wizard.exam.question_id)
        // const modifiedQuestion = {
        //     answers: []
        // }
        // const answers = ['answer_a', 'answer_b', 'answer_c', 'answer_d']
        // const answersTypes = {
        //     'answer_a': 'a',
        //     'answer_b': 'b',
        //     'answer_c': 'c',
        //     'answer_d': 'd',
        // }
        // Object.keys(question).forEach((item) => {
        //     if (answers.includes(item)) {
        //         modifiedQuestion.answers.push({
        //             value: answersTypes[item],
        //             text: question[item]
        //         })
        //     } else {
        //         modifiedQuestion[item] = question[item]
        //     }
        // })
        // return modifiedQuestion
    }
}
