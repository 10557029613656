import { getField } from 'vuex-map-fields';

export const getters = {
    getField,
    getterMainWriterInfo(state) {
        const {
            firstname,
            lastname,
            fullname,
            email,
            phone_code,
            phone,
            sw_id
        } = state.writer_data
        const { auth } = state
        return {
            fullname,
            email,
            phone_code,
            phone,
            id: sw_id,
            auth,
            firstname,
            lastname
        }
    },
    getterIsAuth(state) {
        return state.auth
    },
    getAddressDataFields(state) {
        return {
            address: state.writer_data.address,
            city: state.writer_data.city,
            zip: state.writer_data.zip,
            country: state.writer_data.country,
            region: state.writer_data.region
        }
    },
    getSwId(state) {
        return state.writer_data.sw_id
    },
    getterAccountBalance(state) {
        return state.writer_data.statistics
            ? Number(state.writer_data.statistics.account_balance)
            : 0
    },
    getterWriterDataTicketCounter(state) {
        return state.writer_data.tickets_count
    },
    getterWriterStatistics(state) {
        return state.writer_data.statistics
    },
    getterWriterTokenDate(state) {
        return state.writer_data.expires_date
    },
    getterPermissions(state) {
        const writerData = state.writer_data
        return {
            isTrusted: writerData.trust_status === 'TRUSTED'
        }
    },
    getterPersonalInformation(state) {
        return {
            auth: state.auth,
            avatar_url: state.writer_data.avatar_url,
            avatar_fallback: state.writer_data.avatar_fallback,
            firstname: state.writer_data.firstname,
            lastname: state.writer_data.lastname,
            nickname: state.writer_data.nickname,
            email: state.writer_data.email,
            password: state.writer_data.password
        }
    },
    getter2FA(state) {
        return state.writer_data.twofa
    },
    getterWriterStatuses(state) {
        return {
            accessstatus: state.writer_data.accessstatus,
            status: state.writer_data.status,
            trust_status: state.writer_data.trust_status
        }
    },
    getterWriterPhoneData(state) {
        return {
            phone: state.writer_data.phone,
            phone_code: state.writer_data.phone_code
        }
    },
    getterPaymentsInfo(state) {
        return {
            pay_method: state.writer_data.pay_method,
            paypal_email: state.writer_data.paypal_email
        }
    },
    getterPhoneIsActual() {
        return false
    },
    getterNeedUpdatePhone(state) {
        return state.writer_data.required_input?.phone
    },
    getterNeedUpdateExam(state) {
        return state.writer_data.required_input?.exam
    },
    getterNeedUpdateGeneralInfo(state) {
        return state.writer_data.required_input?.application
    },
    getterHeaderMenuAttention(state, getters) {
        return getters.getterSettingsAttention || getters.getterDocumentsAttention
    },
    getterSettingsAttention(state, getters) {
        return getters.getterNeedUpdatePhone || getters.getterNeedUpdateGeneralInfo
    },
    getterDocumentsAttention(state, getters) {
        return getters.getterNeedUpdateExam
    }
}
