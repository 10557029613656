<template>
    <div
        v-click-outside="clickOutside"
        class="form-select"
        :class="{ 'error-border': validationError, 'success-border' : search || selectedValue, 'grey-border' : openListItem && !selectedValue }"
        @click="openListItem = !openListItem"
    >
        <fieldset :class="[openListItem && !selectedValue ? 'form-select__fieldset active' : 'form-select__fieldset', {'selected-value': selectedValue}]">
            <legend
                class="form-select__legend"
                :class="{ 'error': validationError }"
            >
                {{ label }}
            </legend>
            <component
                :is="prependIcon"
                class="form-input__prepend-icon"
            />
            <div class="form-select__input-block">
                <!-- selected order -->
                <div
                    v-show="selectedValue"
                    class="form-select__input-selected_item"
                >
                    <div
                        class="form-select__input-selected_item-title"
                        style="max-width: unset; width: auto"
                    >
                        <span>{{ selectedValueTitle }}</span>
                    </div>
                    <span class="form-select__input-selected_item-id">{{ selectedValueId }}</span>
                    <!-- clear field button -->
                    <img
                        src="@/assets/img/svg/cross.svg"
                        @click="clearField"
                    >
                    <!-- clear field button -->
                </div>
                <!-- selected order -->
                <!-- pretype input -->
                <input
                    v-show="!selectedValue"
                    class="form-select__input"
                    :value="search"
                    type="text"
                    :name="name"
                    :placeholder="label"
                    autocomplete="off"
                    @focus="$event.target.select()"
                    @input="onInputChanged($event)"
                >
                <!-- pretype input -->
            </div>
            <!-- preloaded results -->
            <ul
                v-if="openListItem && !selectedValue"
                id="listElm"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in showingOrders"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item.orderid)"
                >
                    <p class="form-select__list-item-title">
                        &#171;{{ item.title | ucfirst }}&#187;
                    </p>
                    <p class="form-select__list-item-description">
                        {{ item.created_at | moment_from }} ago: {{ item.orderid }}
                    </p>
                </li>
            </ul>
            <!-- preloaded results -->
            <!-- validation error -->
            <div
                v-if="validationError"
                class="form-select__error"
            >
                {{ validationError }}
            </div>
            <!-- validation error -->
        </fieldset>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import filtersMixin from '@/mixins/filtersMixin';

export default {
    name: 'FormSelectProject',
    directives: {
        ClickOutside
    },
    mixins: [
        filtersMixin
    ],
    props: {
        value: {
            type: String
        },
        prependIcon: {
            type: String
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        name: {
            type: String,
            required: true
        },
        orders: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            openListItem: false,
            filteredItems: [],
            ordersData: {},
            search: '',
            reqId: null
        };
    },
    computed: {
        selectedValue() {
            if (this.orders) {
                const isExist = this.orders.some((el) => (el.orderid || el.title) === this.search);
                if (isExist) {
                    const order = this.orders.find((el) => (el.orderid || el.title) === this.search)
                    return order
                }
            }
            return null
        },
        selectedValueTitle() {
            return this.selectedValue ? this.selectedValue.title : ''
        },
        selectedValueId() {
            return this.selectedValue ? `(ID: ${this.selectedValue.orderid})` : ''
        },
        validationError() {
            return this.errors ? this.errors : '';
        },
        showingOrders() {
            return this.orders.filter((order) => order.title.includes(this.search) || order.orderid.includes(this.search))
        }
    },
    watch: {
        selectedValue(value) {
            if (value) {
                this.setTitleWidth()
            }
        }
    },
    async mounted() {
        this.search = this.value;
        window.addEventListener('resize', this.setTitleWidth)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setTitleWidth)
        window.cancelAnimationFrame(this.reqId)
    },
    methods: {
        selectItem(value) {
            this.$emit('input', value);
            this.search = value
            this.openListItem = false;
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = (event.target.value.toLowerCase()).charAt(0).toUpperCase() + (event.target.value.toLowerCase()).slice(1);
            if (this.search === '') {
                this.$emit('input', '');
            }
            if (this.search !== '') {
                this.$emit('input', this.search);
            }
        },
        setInitValue() {
            this.search = this.value
        },
        clickOutside() {
            this.openListItem = false
        },
        setTitleWidth() {
            const title = document.querySelector('.form-select__input-selected_item-title')
            const setOnResize = () => {
                // title width + id width + 2 margins(10/4px) + cross width + padding in form select + padding in form-select__input-block
                const block = document.querySelector('.form-select')
                const titleSpan = document.querySelector('.form-select__input-selected_item-title span')
                const id = document.querySelector('.form-select__input-selected_item-id')
                const titleText = title.textContent
                const blockWidth = block.offsetWidth
                const titleSpanWidth = titleSpan.offsetWidth
                const idWidth = id.offsetWidth
                const elementsWidth = titleSpanWidth + idWidth + 14 + 12 + 10 + 20
                if (titleText.length && titleSpanWidth && idWidth) {
                    if (elementsWidth < blockWidth) {
                        title.style.maxWidth = 'unset'
                        title.style.width = 'auto'
                    } else {
                        title.style.maxWidth = `${blockWidth - idWidth - 14 - 12 - 10 - 20}px`
                        title.style.width = `${blockWidth - idWidth - 14 - 12 - 10 - 20}px`
                    }
                }
            }
            if (title) {
                this.reqId = window.requestAnimationFrame(setOnResize)
            }
        },
        clearField() {
            this.search = ''
            this.$emit('input', '')
        }
    }
};
</script>

<style lang="scss" scoped>
.sort-button {
    margin: 10px auto;
}
.form-select {
    outline: none;
    // border: 1px solid #27d91f ;
    border: 1px solid #D3D9E2;
    -webkit-border-radius: $input-border-radius;
    -moz-border-radius: $input-border-radius;
    border-radius: $input-border-radius !important;
    max-height: 52px!important;
    width: 100%;
    &.success-border {
        border: $input-border-success;
    }
    &.grey-border {
        border: $input-border;
    }
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }
    &__fieldset {
        position: relative;
        border: none ;
        outline: none;
        &.selected-value {
            &:after {
                display: none;
            }
        }
    }
    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -12px;
        background-color: $white;
        border: none;
    }
    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding-left: 20px;
        width: 100%;
        cursor: pointer;
        border: none ;
        outline: none;
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
        font-family: $font-family-base, sans-serif ;
        font-size: 14px;
        max-height: 46px;
        height: 46px;
    }
    &__list {
        overflow-y: scroll;
        max-height: 270px;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 55px;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
    }
    &__list-item {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 1px;
        cursor: pointer;
        border-bottom: 1px solid #e9e9e9;
        &:hover {
            background-color: lightgrey;
            //color: #FFF;
        }
        &--selected {
            background-color: rgba(230, 230, 230, 1);
            &:hover {
                background-color: rgba(230, 230, 230, 1);
                //color: #FFF;
            }
        }
    }
    &__list-item-title {
        margin-bottom: 2px;
        color: rgba(0,0,0,.8);
    }
    &__list-item-description {
        color: rgba(0,0,0,.6);
        margin-top: 6px;
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
        padding: 0 3px;
    }
}
.form-select__input-block {
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 46px;
    height: 46px;
    padding-left: 5px;
    padding-right: 5px;
    .form-select__input-selected_item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 4px;
        font-family: $font-family-base, sans-serif;
        font-size: 14px;
        max-height: 46px;
        height: 36px;
        background: #F4F4F4;
        span {
            white-space: nowrap;
            line-height: 22px;
        }
        &-title {
            margin-right: 4px;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
        &-id {
            margin-right: 10px;
        }
        img {
            width: 10px;
            height: 10px;
        }
    }
    .form-select__input {
        padding-left: 10px;
    }
}

</style>
