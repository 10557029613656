export const getters = {
    getterNotification(state) {
        return state.notification || []
    },
    getterTypes(state) {
        return state.pricing.type
    },
    getterTurnarounds(state) {
        return state.pricing.turnaround
    },
    getterLevels(state) {
        return state.pricing.level
    }
}
