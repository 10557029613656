export const initialState = {
    wizard: {
        steps: {
            currentStep: 1,
            lastValidStep: 1,
            allStepsCount: 6
        },
        exam: {
            status: 'begin',
            questions: [],
            time: null,
            question_id: 0,
            answers: [],
            result: 0,
            count: 0
        }
    }
}

export const state = initialState
