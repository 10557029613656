import { cloneDeep } from 'lodash'

import { updateField } from 'vuex-map-fields'
import { SchemaSupport } from '@/models/SchemaSupport.js'

import {
    RESET,
    UPDATE,
    CLEAR_FORM,
    SET_TICKETID,
    UNSET_TICKETID,
    CLEAR_MESSAGE
} from './mutation-types.js'

import { initialState } from './state.js'

export const mutations = {
    updateField,
    [RESET](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [CLEAR_FORM](state) {
        state.form_data = new SchemaSupport()
    },
    [SET_TICKETID](state, payload) {
        state.form_data.ticketid = payload.id
    },
    [CLEAR_MESSAGE](state) {
        state.form_data.message = ''
    },
    [UNSET_TICKETID](state) {
        state.form_data.ticketid = null
    },
    [UPDATE]: (state, payload) => {
        state.payload = { ...payload }
    }
}
