<template>
    <div id="app">
        <div class="page-wrapper">
            <keep-alive v-if="$route.meta.keepAlive">
                <router-view class="page-content" />
            </keep-alive>
            <router-view
                v-else
                class="page-content"
            />
        </div>
        <global-modals />
        <Notifier v-if="$socket.isConnected" />
        <update-notifications />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import GlobalModals from '@/components/GlobalModals.vue'
import Notifier from '@/components/Notifier/Notifier.vue'
import UpdateNotifications from '@/components/update-notifications/Main.vue'
// mixins
import authMixin from '@/mixins/global/authMixin.js'
import loggedInUserMixin from '@/mixins/global/loggedInUserMixin.js'

import moment from 'moment/moment';

// types
import {
    ACTION_UPDATE_WRITER_DATA,
    ACTION_WRITER_TOUCH
} from '@/store/modules/writer/action-types.js'
import {
    CLEAR_TOUCH_INTERVAL
} from '@/store/modules/writer/mutation-types.js'
import { eventBus } from '@/helpers/event-bus/'

const {
    mapActions: mapWriterActions,
    mapMutations: mapWriterMutations
} = createNamespacedHelpers('writer')

export default {
    components: {
        GlobalModals,
        Notifier,
        UpdateNotifications
    },
    mixins: [
        authMixin,
        loggedInUserMixin
    ],
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s | FreelanceWriting.center'
    },
    data() {
        return {
            isOffline: false
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterIsAuth'
        ])
    },
    watch: {
        '$route.name': function () {
            if (this.isOffline) {
                this.connectionError()
            }
        },
        async getterIsAuth(val) {
            if (val) {
                await this[ACTION_WRITER_TOUCH]()
                if (!this.$socket.isConnected) {
                    this.$socket.connect()
                }
            } else {
                this[CLEAR_TOUCH_INTERVAL]()
            }
        }
    },
    async created() {
        // offline listener
        window.addEventListener('offline', () => {
            this.connectionError()
            this.isOffline = true
        })
        window.addEventListener('online', () => {
            eventBus.$emit('showSnackBar', 'The connection was restored', 'success')
            this.isOffline = false
            setTimeout(() => window.location.reload(), 500)
        });

        /* Update client data  */
        if (this.getterIsAuth) {
            if (!this.$socket.isConnected) {
                this.$socket.connect()
            }
            await Promise.all([this[ACTION_UPDATE_WRITER_DATA](), this[ACTION_WRITER_TOUCH]()])
        }

        await this.getBackendVersion()
    },
    methods: {
        ...mapWriterActions([
            ACTION_WRITER_TOUCH,
            ACTION_UPDATE_WRITER_DATA
        ]),
        ...mapWriterMutations([
            CLEAR_TOUCH_INTERVAL
        ]),
        connectionError() {
            const errorString = 'There is a problem with the internet connection, try refreshing the page please.'
            eventBus.$emit('showSnackBar', errorString, 'error')
        },
        async getBackendVersion() {
            try {
                const resp = await fetch(`${process.env.VUE_APP_API_URL}/api/data/api-version`)
                const data = await resp.json();
                console.group('BACKEND')
                console.log(
                    '%c environment ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.env} `
                );
                console.log(
                    '%c version ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.commit_hash} `
                );
                console.log(
                    '%c build time ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #00897B;padding: 3px 3px; border-radius: 4px;',
                    ` ${data.date_readable}, (${data.updated_when})`
                );
                console.groupEnd()
                console.group('FRONTEND')
                console.log(
                    '%c environment ',
                    'color: #fff; font-style: italic; background-color: #5E35B1; padding: 3px 3px; border-radius: 4px;',
                    ` ${process.env.VUE_APP_ENV} `
                );
                console.log(
                    '%c version ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #5E35B1;padding: 3px 3px; border-radius: 4px;',
                    ` ${process.env.VUE_APP_VERSION} `
                );
                console.log(
                    '%c build time ',
                    'color: #fff; font-style: italic; font-weight: 700; background-color: #5E35B1;padding: 3px 3px; border-radius: 4px; ',
                    ` ${moment(process.env.VUE_APP_BUILD_DATETIME).format('YY-MM-DD h:mm')}, (${moment(moment(process.env.VUE_APP_BUILD_DATETIME)).fromNow(true)})`
                );
                console.groupEnd()
            } catch (e) {
                console.error(e);
            }
        }
    }
}
</script>
