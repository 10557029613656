<template>
    <div
        v-click-outside="handleBlur"
        class="form-select form-select-country"
        :class="{ 'error-border': validationError, 'success': isValid, 'empty' : !countryObject }"
    >
        <fieldset class="form-select__fieldset form-select-country__fieldset">
            <legend class="form-select__legend">
                {{ label }}
            </legend>
            <div
                class="form-select-country__block"
            >
                <span
                    v-if="countryObject"
                    id="phone-code"
                    class="phone-code"
                    @click="openListItem = !openListItem"
                >
                    <span :class="'flag-icon flag-input-icon flag-icon-' + countryObject.iso.toLowerCase()" />
                    <span>{{ countryObject.text }}</span>
                </span>
                <input
                    id="phone-select__input"
                    ref="code"
                    autocomplete="off"
                    :style="{paddingLeft: countryObject ? '5px' : '10px'}"
                    class="form-select__input"
                    type="text"
                    @click="openList"
                    @focus="$event.target.select()"
                    @keyup="onInputChanged($event)"
                >
                <span
                    :class="{active: openListItem}"
                    class="select-arrow"
                    @click="openListItem = !openListItem"
                >
                    <chevron-down />
                </span>
            </div>
            <!-- dropdown select -->
            <ul
                v-if="openListItem"
                class="form-select__list"
            >
                <li
                    v-for="(item, index) in countryList"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item)"
                >
                    <div :class="'flag-icon flag-icon-' + item.iso.toLowerCase()" />
                    {{ item.text }}
                </li>
            </ul>
            <!-- dropdown select -->
            <span class="form-input__error">{{ validationError }}</span>
        </fieldset>
    </div>
</template>
<script>
// import ClickOutside from 'vue-click-outside'
import Api from '@/helpers/api/index.js'
import ChevronDown from 'mdi-vue/ChevronDown'

export default {
    name: 'FormSelectCountries',
    // directives: {
    //     ClickOutside
    // },
    components: {
        ChevronDown
    },
    props: {
        value: {
            type: String
        },
        label: {
            type: String
        },
        errors: {
            type: String
        },
        isValid: {
            type: Boolean
        },
        selectType: {
            type: String
        },
        country: {
            type: String
        }
    },
    data() {
        return {
            openListItem: false,
            COUNTRIES: [],
            countryList: [],
            search: ''
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : ''
        },
        countryObject() {
            return this.COUNTRIES.find((item) => item.iso === this.country)
        }
    },
    async mounted() {
        await this.getCountries()
    },
    methods: {
        openList() {
            this.openListItem = !this.openListItem;
            if (!this.countryObject) {
                this.countryList = [...this.COUNTRIES]
                return
            }
            this.countryList = [...this.COUNTRIES.filter((item) => item.value !== this.countryObject.value)]
        },
        async selectItem(value) {
            await this.$emit('update:field', value.iso)
            this.openListItem = false
            this.search = ''
            this.$refs.code.value = ''
        },
        async getCountries() {
            const response = await Api.get('/api/data/countries')
            this.COUNTRIES = [...response.data]
            this.countryList = [...response.data]
        },
        handleBlur() {
            if (this.openListItem) {
                this.openListItem = false
            }
        },
        onInputChanged(event) {
            this.openListItem = true
            if (event.code === 'Backspace' && this.search.length === 0) {
                this.$emit('update:field', '')
                return;
            }
            this.search = event.target.value
            if (!this.search) {
                this.countryList = this.COUNTRIES
                return
            }
            const countriesArray = this.COUNTRIES.filter((item) => item.text.toLowerCase().includes(this.search.toLowerCase()))
            this.countryList = countriesArray
        }
    }
}
</script>

<style scoped lang="scss">
    .form-select {
        outline: none;
        border-radius: 4px 0 0 4px;
        max-height: 50px;
        width: 100%;
        &.error-border {
            border: $input-border-error !important;
        }
        &.success {
            border: $input-border-success !important;
        }
        &-country__block {
            display: flex;
            align-items: center;
            height: 48px;
            max-height: 48px;
            border-radius: $input-border-radius 0 0 $input-border-radius;
            cursor: pointer;
            box-sizing: border-box;
            width: 100%;
            .active {
                svg {
                    transform: rotate(-180deg);
                }
            }
            .form-select__input {
                height: unset;
            }
        }
        &__icon {
            position: absolute;
            top: 10px;
            left: 12px;
        }
        &__fieldset {
            position: relative;
            border: none;
            outline: none;
        }
        &__legend {
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -10px;
            background-color: $white;
            border: none;
        }
        &__input {
            display: inline-block;
            border: none !important;
            outline: none;
            &:focus, &:active {
                border: none !important;
            }
            &.empty {
                padding-left: 10px;
            }
        }
        &__error {
            display: inline;
            width: auto;
            text-align: left;
            color: $error-color;
            caret-color: $error-color;
            font-size: 12px;
            min-height: 14px;
            position: relative;
            position: absolute;
            left: 13px;
            bottom: -7px;
            background: white;
        }
        &__list {
            overflow-y: scroll;
            max-height: 270px;
            background-color: white;
            position: absolute;
            z-index: 10;
            top: 55px;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 0;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
            border-radius: $input-border-radius;
        }
        &__list-item {
            display: flex;
            flex-direction: row;
            padding: 15px;
            background-color: #fff;
            margin-bottom: 1px;
            cursor: pointer;
            &:hover {
                background-color: lightgrey;
            }
            .flag-icon {
                margin-right: 10px;
            }
        }
        .phone-code {
            cursor: pointer;
            margin-left: 10px;
            font-size: 14px;
            display: flex;
            align-content: center;
            white-space: nowrap;
            align-items: center;
            .flag-icon {
                margin-right: 4px;
            }
        }
    }
    .select-arrow {
        margin-right: 10px;
    }
    .form-select-country {
        .form-select__fieldset::after, .form-select-writers__fieldset::after {
            display: none;
        }
    }
</style>
