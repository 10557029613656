export function removeEmptyParamsFromRequest(obj) {
    const query = Object.entries(obj)
    // eslint-disable-next-line no-unused-vars
        .filter(([_, v]) => v !== '')
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    return query
}

export function convertToMoney(str) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD', currencyDisplay: 'symbol', minimumFractionDigits: 2
    }).format(str)
}

export function convertToNumberFormat(str) {
    return new Intl.NumberFormat('en-US').format(str)
}
