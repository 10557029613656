import {
    CLEAR_LIST_NOTIFICATIONS,
    SET_PRICING,
    SET_NOTIFICATIONS,
    DELETE_NOTIFICATIONS
} from './mutation-types.js';

export const mutations = {
    [SET_PRICING](state, payload) {
        state.pricing = payload;
    },
    [CLEAR_LIST_NOTIFICATIONS](state) {
        state.notification = []
    },
    [SET_NOTIFICATIONS](state, payload) {
        // payload.data.forEach((element) => {
        //     state.notification = state.notification.filter((item) => item.id !== element.id)
        //     state.notification.push(element)
        // });
        state.notification.push(...payload.data)
    },
    [DELETE_NOTIFICATIONS](state, payload) {
        state.notification = state.notification.filter((item) => item.id !== payload)
    }
}
