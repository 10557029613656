<template>
    <div
        class="support-messenger_chat"
    >
        <div class="support-messenger_chat_wrapper">
            <loading-overlay v-if="listLoading && !tmpMessages.length" />
            <template v-else>
                <message
                    v-for="(messageData, message_index) in tmpMessages"
                    :key="message_index"
                    :message="messageData"
                />
            </template>
        </div>

        <transition
            mode="out-in"
            name="slide-y"
        >
            <BotAnimated v-if="showBot" />
        </transition>

        <MessageForm
            :is-loading="isLoading"
            :clear-form="clearForm"
            @typing="typingListener"
            @sendMessage="sendMessage"
            @formCleared="clearForm = false"
        >
            <template #typing>
                <span>{{ typingMessage }}</span>
                <div
                    v-if="typingMessage"
                    class="dot-flashing"
                />
            </template>
        </MessageForm>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
// Validation
import filtersMixin from '@/mixins/filtersMixin';
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { required } from 'vuelidate/lib/validators'
import { formMessages } from '@/validation/support/Messages'
import {
    sendMessage, markAsReadTicket
} from '@/services/support/supportApi'
// import Avatar from '@/components/common/Avatar';
import BotAnimated from '@/components/support/supportMessenger/BotAnimated.vue'
import MessageForm from '@/components/common/MessageForm';
import LoadingOverlay from '@/components/LoadingOverlay';
import Message from '@/components/common/Chat/Message.vue'

export default {
    name: 'SupportMessengerChat',
    components: {
        // Avatar,
        BotAnimated,
        MessageForm,
        Message,
        LoadingOverlay
    },
    mixins: [
        validationMixin,
        filtersMixin
    ],
    props: {
        messages: {
            type: Array,
            required: true
        },
        ticketid: {
            type: String,
            required: true
        },
        hash: {
            type: String,
            required: false
        },
        listLoading: {
            type: Boolean,
            required: false
        }
    },
    validations: {
        message: { required }
    },
    data() {
        return {
            message: '',
            tmpMessages: [],
            isLoading: false,
            timeout: false,
            typing: false,
            typingMessage: null,
            clearForm: false
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterIsAuth',
            'getterMainWriterInfo'
        ]),
        showBot() {
            return this.messages.length === 1 && this.timeout
        }
    },
    watch: {
        message() {
            this.typing = true
            this.userTyping(this)
        },
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticketid
            })
        },
        'messages.length': function () {
            this.tmpMessages = [...this.messages]
            this.$nextTick(async () => {
                this.scrollToEnd()
                await this.markAsRead()
            })
        }
    },
    async created() {
        setTimeout(() => {
            this.timeout = true
        }, 5000);
        this.tmpMessages = [...this.messages]
        this.chatWSConnect()
        await this.markAsRead()
    },
    mounted() {
        this.scrollToEnd()
    },
    beforeDestroy() {
        this.$socket.emit('support:unsubscribe', this.ticketid)
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async sendMessage({ message }) {
            try {
                this.isLoading = true
                const data = await sendMessage({
                    ticketid: this.ticketid,
                    message,
                    hash: this.hash
                }, this.getterIsAuth)
                this.tmpMessages.push(data)
                this.message = ''
                this.$v.$reset()
                this.$nextTick(() => {
                    this.scrollToEnd()
                })
                this.clearForm = true
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false
            }
        },
        async keyListener(e) {
            if (e.ctrlKey && e.keyCode === 13) {
                this.message += '\n'
                return
            }
            if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13) {
                e.preventDefault();
                if (!this.getterIsAuth) {
                    this.$refs.recaptcha.buttonClick()
                    return
                }
                await this.sendMessage()
            }
        },
        scrollToEnd() {
            const elem = document.querySelector('.support-messenger_chat_wrapper')
            elem?.scrollTo({ top: 99999999, behavior: 'smooth' })
        },
        async markAsRead() {
            await markAsReadTicket({
                ticketid: this.ticketid,
                hash: this.hash
            }, this.getterIsAuth)
            this.$emit('readMessages', this.ticketid)
        },
        SplitByCharacters(text) {
            const splitString = text.split('\n').join('<br>')
            return splitString
        },
        chatItemClasses(message_index) {
            return {
                label_new: this.tmpMessages.some((item) => item === message_index)
            }
        },
        chatWSConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticketid, hash: this.getHash })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author.id === this.getterMainWriterInfo.id) return
                this.tmpMessages = [...this.tmpMessages, data]
                this.$nextTick(() => {
                    this.scrollToEnd()
                })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Writer' || data.role === 'Lead') return
                this.typingMessage = data.message
            })
            this.$socket.on('disconnect', () => {
                this.chatWSConnect()
            });
        },
        typingListener(value) {
            this.typing = value
        }
    }
}
</script>
