import Api from '@/helpers/api/index.js'

import {
    GET_DATA,
    DELETE_DATA,
    CREATE_BOOKMARK,
    GET_LOAD_MORE
} from './action-types.js'

import {
    SET_DATA,
    DELETE_DATA_BOOKMARKS,
    CREATE_BOOKMARK_ITEM,
    SET_LOAD_MORE
} from './mutation-types.js'

export const actions = {
    async [GET_DATA]({ commit }, payload) {
        const { data } = await Api.get('/api/bookmark/fetch', payload, { dialog: true }, false)
        commit(SET_DATA, data.data)
        return data
    },

    async [CREATE_BOOKMARK]({ commit }, payload) {
        const { data } = await Api.post('/api/bookmark/create', payload)
        commit(CREATE_BOOKMARK_ITEM, data)
        return data
    },

    async [DELETE_DATA]({ commit }, payload) {
        await Api.delete('/api/bookmark/delete', payload)
        commit(DELETE_DATA_BOOKMARKS, payload)
    },

    async [GET_LOAD_MORE]({ commit }, payload) {
        const { data } = await Api.get('/api/bookmark/fetch', payload)
        commit(SET_LOAD_MORE, data.data)
    }
}
