import { updateField } from 'vuex-map-fields'
import {
    SET_DASHBOARDS,
    SET_ORDER_DASHBORD_COUNTER,
    SET_OPEN_TICKETS_COUNTER,
    SET_FEEDBACKS_COUNTER
} from './mutation-types.js';

export const mutations = {
    updateField,
    [SET_DASHBOARDS](state, payload) {
        state.dashboards = payload;
    },
    [SET_ORDER_DASHBORD_COUNTER](state, payload) {
        state.orderCounter = payload;
    },
    [SET_OPEN_TICKETS_COUNTER](state, payload) {
        state.ticketsOpenCounter = payload
    },
    [SET_FEEDBACKS_COUNTER](state, payload) {
        state.feedbacksCounter = payload
    }
}
