// vuex
import { createNamespacedHelpers, mapGetters } from 'vuex'
import {
    ACTION_UPDATE_WRITER_DATA
} from '@/store/modules/writer/action-types.js'

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer')

export default {
    computed: {
        ...mapGetters('writer', [
            'getterIsAuth',
            'getSwId'
        ])
    },
    watch: {
        getterIsAuth(val) {
            if (val) {
                this.$socket?.reconnect()
            } else {
                this.$socket?.emit('forceDisconnect');
            }
        }
    },
    methods: {
        ...mapWriterActions([
            ACTION_UPDATE_WRITER_DATA
        ])
    },
    async created() {
        if (this.getterIsAuth && !this.getSwId) {
            await this[ACTION_UPDATE_WRITER_DATA]()
        }
    }
}
