<template>
    <div class="not-found wrapper">
        <div class="not-found_img" />
        <p class="not-found-top">
            {{ text }}
        </p>
        <p class="not-found-bottom">
            {{ desc }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'No posts found'
        },
        desc: {
            type: String,
            default: 'Please enter another text to search'
        }
    }
}
</script>
