<template>
    <div class="support-messenger_cards-faq">
        <ul class="support-messenger_cards">
            <li
                v-for="(answer, index) in listSearch.slice(0, countQuestion)"
                :key="index"
                class="support-messenger_cards-card"
                :class="{ 'faq-list-item--active' : activeQuestion === index }"
                @click.prevent="toggle(index)"
            >
                <div
                    class="support-messenger_cards-header"
                >
                    <span class="support-messenger_cards-count">{{ index + 1 }}</span>
                    <span class="support-messenger_cards-body">{{ answer.q }}</span>
                </div>

                <div
                    v-show="activeQuestion === index"
                    class="support-messenger_cards-answer"
                >
                    {{ answer.a }}
                </div>
            </li>
        </ul>
        <div class="support-messenger_cards-footer">
            <button
                class="btn-base btn-main"
                @click="goToCreate"
            >
                Send us a message
            </button>
        </div>
    </div>
</template>

<script>
import { FaqData } from '@/helpers/faqDataClass.js'

export default {
    props: {
        count: {
            type: Number,
            default: 100
        },
        keyword: {
            type: String,
            default: 'essay'
        },
        title: String
    },
    data() {
        return {
            activeQuestion: null,
            countQuestion: this.count,
            search: ''
        }
    },
    computed: {
        listSearch() {
            return this.list.filter((item) => item.q.toLowerCase().includes(this.search.toLowerCase()))
        },
        list() {
            const faqData = new FaqData(this.keyword)
            const faqText = faqData.getData()
            return faqText
        }
    },
    methods: {
        toggle(val) {
            this.activeQuestion = this.activeQuestion === val ? null : val
        },
        showMore() {
            if (this.countQuestion < this.list.length) {
                this.countQuestion += 3
            }
        },
        goToCreate() {
            this.$emit('goToCreate')
        }
    }
}
</script>
