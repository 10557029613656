import Api from '@/helpers/api/index.js'

import {
    GET_DASHBOARDS
} from './action-types.js'

import {
    SET_DASHBOARDS
    // SET_ORDER_DASHBORD_COUNTER
    // SET_OPEN_TICKETS_COUNTER,
    // SET_FEEDBACKS_COUNTER
} from './mutation-types.js'

export const actions = {
    async [GET_DASHBOARDS]({ commit }) {
        const { data } = await Api.get('/api/dashboard/get-counters')
        // const ticketOpen = data.tickets.find(({ status }) => status === 'OPEN')
        // const feedbacksCounter = data.feedbacks_total
        // commit(SET_FEEDBACKS_COUNTER, feedbacksCounter)
        // commit(SET_OPEN_TICKETS_COUNTER, ticketOpen?.count)
        commit(SET_DASHBOARDS, data)
        // commit(SET_ORDER_DASHBORD_COUNTER, data.orders_total)
    }
}
