export class CachedRequestsStore {
    requests = {};

    cacheThreshold = process.env.VUE_APP_CACHE_THRESHOLD || 30000;

    // eslint-disable-next-line class-methods-use-this
    #getRequestKey(
        url,
        payload
    ) {
        return JSON.stringify({
            url,
            payload
        })
    }

    checkCachedRequest(
        url,
        payload
    ) {
        const key = this.#getRequestKey(url, payload)
        if (!this.requests[key] || Date.now() - this.requests[key] > this.cacheThreshold) {
            this.requests[key] = Date.now()
            return false
        }
        return true
    }
}
