export class FaqData {
    constructor(keyword) {
        this.keyword = keyword || 'paper'
    }

    getData = () => {
        const faq = [
            {
                id: 1,
                q: 'Who are the clients?',
                a: `
                    Our clientele is composed mostly of individuals and business clients in need of custom research/writing.
                    We do get many orders for academic projects from students who cannot complete their requirements for a variety of reasons (e.g., inadequate time).
                    As many of our clients are students, some of our writers have concerns over the ethics of the work. We believe that these concerns can be laid to rest for the following reasons:
                    <p>1) First, the students who avail our service are smart and intelligent enough and can usually complete the projects  on  their  own.  However, they opt to come to us for a variety of valid reasons including getting overwhelmed by too many requirements, being preoccupied by personal, family, or health issues, and choosing to prioritize courses relevant to their majors.</p>
                    <p>2)  Second, our agreement with clients stipulates that clients cannot submit the papers written by our writers as their own work without significant editing/rewriting. They primarily serve as informational/supplemental materials. The majority of clients observe that rule.</p>
                    `
            },
            {
                id: 2,
                q: 'What kind of research will I do?',
                a: 'The orders in our system cover a long list of academic subjects, areas, disciplines. These include but are not limited to the physical and life sciences, accounting and mathematics, social sciences, the humanities, and business. You will have plenty of opportunities to research different topics. The extent and depth of research depends on the specific requirements of the order. Some may require light research for credible web pages while others may require more extensive research using online libraries and databases. In a way, it will feel like you are in school again. But the writing process will, of course, contribute to your self-development, since you will be gaining new knowledge and enhancing your skills as you go along.'
            },
            {
                id: 3,
                q: 'If students buy papers, isn’t it cheating?',
                a: 'No. As stated earlier, our agreement states that our papers are intended for informational/supplemental purposes. Our papers are not intended for direct submission. Papers sold need to be edited/rewritten by the client.'
            },
            {
                id: 4,
                q: 'How competent are your writers?',
                a: 'Being an internet company, we do recruiting globally. The Internet enables us to find qualified writers and researchers without having to gather them in some physical facility. Our employees work from their homes, however, that does not mean we just pull people off the street. Our human resource managers check every applicant very carefully, check their credentials, review their work samples, assign test projects to see if their claimed qualifications are real. We do not hire writers who fail our rigid tests.'
            },
            {
                id: 5,
                q: 'So how much can I make?',
                a: 'The amount you will make depends on the amount of work you perform. You are provided with a lot of flexibility, which means you get to decide how many orders you get to work on. Note that fees also depend on your status as a writer. Writers who constantly exhibit their competence eventually earn higher fees. Most writers earn between $200 and well over $2000 a month.'
            },
            {
                id: 6,
                q: 'I just got hired and there are no projects! Do you guys even have clients?',
                a: 'We have a discount program for return clients. You can find the details on our discounts page. If you were given a discount code for a future order, you can enter the code during the ordering process.'
            },
            {
                id: 7,
                q: 'Do you send a plagiarism report along with the completed writing',
                a: 'We have thousands of clients from all over the globe, which means there is a constant influx of orders. However, as there are many writers in this company, the competition can be quite stiff. Thus, you need to be vigilant and patient. Staying on the system will help you keep track of incoming orders. As our business coincides with the academic calendar in predominantly English-speaking countries like the US and the UK, there tend to be high and low seasons. The months of March, April, May, October, November, and December have more orders than the other months of the year.'
            },
            {
                id: 8,
                q: 'What is a bid?',
                a: 'A bid is the act of stating your desire or intention to sign up for and complete an order. This function requires you to state the amount you request and the number of hours it will take you to complete the order. Bids are reviewed by support operators and orders are assigned based on merit.'
            },
            {
                id: 9,
                q: 'Why wasn’t my bid accepted?',
                a: 'Competition can be quite stiff and there are a number of reasons why your bid may not be accepted. In general, new writers are assigned only shorter and easier orders while bigger and more complex orders are assigned to more experienced writers. Hence, avoid bidding on big orders. Instead, focus more on smaller orders. You can start working on bigger orders as you gain more privileges.'
            },
            {
                id: 10,
                q: 'Do you impose fines on your writers?',
                a: 'Yes.  We  impose  fines  on  our  writers  for  violations  of  the  provisions  in  the  Writer’s  Agreement  and negligent  conduct  that  jeopardizes  clients.  These  include  but  are  not  limited  to  delays  in  submitting papers, plagiarism, dropping orders without valid reasons, poor quality, and poor communication among others.'
            },
            {
                id: 11,
                q: 'What payment methods do you offer? What credit cards do you accept?',
                a: 'We have two separate payment gateways: Worldpay.com (London, GB) and Paypal. The buyer can select the desired gateway during the ordering process. RBS Worldpay provides fast and secure card authorization directly on our site for VISA and MasterCard users. If Paypal is chosen, the buyer will be taken to a secure webpage that will accept the credit card information. Paypal accepts all major credit debit cards, Paypal funds and on-line checks (the buyer can pay with a bank account).'
            },
            {
                id: 12,
                q: 'How many days does it take for my payout to arrive?',
                a: 'Payout may take time and is subject to a variety of factors including banking holidays. In some cases, it may take as many as five business days before the payout process is complete. Delays may happen due to unforeseen circumstances. As such, we advise that you request payout as early as possible.'
            },
            {
                id: 13,
                q: 'What advice can you give for me to grow in your company and earn well?',
                a: 'Quality and consistency are the two keys to succeeding as a freelance writer in this company. If you are a new writer, make sure you produce high-quality papers. Be patient with your progress. It is better to gradually build a solid reputation as a reliable writer than rush your progress and end up producing low-quality  papers.  Be  professional  at  all  times.  Communicate  with  clients  and  the  support  team  efficiently and  follow  the  advice  of  the  QA  Team.  Adhere  to  the  standards  and  policies  of  the  company.  Strive  to provide customer satisfaction. Orders will automatically come to you when clients are satisfied.'
            }
        ]
        return faq
    }
}
