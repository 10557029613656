export const initialState = {
    token: null,
    created_at: null,
    expires_at: null,
    questions_total: null,
    questions_left: null,
    examStatus: 'start',
    question: null,
    answers: null,
    answerLoading: false,
    questionLoading: true,
    countdown: null
}

export const state = initialState
