import moment from 'moment'

export default {
    filters: {
        money(value) {
            let output = ''
            if (value >= 0) {
                const newValue = Number(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                output = `$${newValue}`
            }
            return output
        },
        credits_balance(value) {
            const newValue = Number(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            return `$${newValue}`
        },
        number(value) {
            return Number(value).toLocaleString('en-US')
            // if (value) {
            //     const newValue = Number(value).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            //     return newValue
            // }
            // return false
        },
        pages(value) {
            let template = ''
            if (value === 1) {
                template = `${value} page`
            } else {
                template = `${value} pages`
            }
            return template
        },
        percent(value) {
            // return value * 100 + "%"
            return `${Math.round(+value * 10000) / 100}%`
        },
        short(value) {
            if (value) {
                const newValue = value.substr(0, 15)
                return `${newValue}...`
            }
            return ''
        },
        moment_from(value) {
            return moment(moment.utc(value)).fromNow(true)
        },

        moment_to_now(value) {
            return moment().add(value, 'hours').format('D MMM')
        },
        moment_to_now_custom(value) {
            return moment().add(value, 'hours').fromNow(true)
        },
        moment_total(value) {
            return moment().add(value, 'hours').format('MMM D dddd, HH:mm')
        },
        moment_full_text(value) {
            return moment(value).format('LLLL')
        },
        moment_short_text(value) {
            return moment(value).format('ddd, MMM D, YY h:mm A')
        },
        moment_bidding_text(value) {
            return moment(value).format('MMM D, YYYY')
        },
        moment_day_text(value) {
            return moment(value).format('D MMM')
        },
        file_size(value) {
            if (((Number(value) / 1000) / 1024) > 1) {
                return `${(Number(value) / 1000 / 1024).toFixed(2)} mb`
            }
            return `${(Number(value) / 1000).toFixed(2)} kb`
        },
        shortName(value) {
            // used in the Attachments.vue
            if (!value) return ''
            const newValue = value.toString()
            let output
            if (newValue.length > 15) {
                const fileType = value.split('.').slice(-1).join();
                const fileName = value.split('.').slice(0, 1).join();
                output = `${fileName.substring(0, 10)}...${fileType}`
            } else {
                output = value
            }
            return output
        },
        fileType(value) {
            // used in the Attachments.vue
            const newValue = value.toString();
            return newValue ? newValue.split('.').slice(-1).join() : '';
        },
        paragraph(value) {
            return value.replace(/\n/g, '<br />')
        },
        ucfirst(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        wordQuantity(value, qty) {
            return qty > 1 ? `${value}s` : value
        },
        paragraphAddBreaks(value) {
            const newValue = value.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>')
            return newValue
        },
        striphtml(value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            return text
        }
    }
}
