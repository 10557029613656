import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import Auth from '@/helpers/auth'

import { ACTION_RESET_ALL_MODULES, ACTION_LOGOUT_WRITER } from '@/store/modules/writer/action-types.js'

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

// this mixin contains the logic for session expiry
export default {
    data: () => ({
        authTimer: null
    }),
    computed: {
        ...mapGetters('writer', ['getterWriterTokenDate', 'getterIsAuth'])
    },
    watch: {
        getterIsAuth(newVal) {
            if (newVal) {
                this.startAuthTimer()
            } else {
                clearInterval(this.authTimer)
            }
        }
    },
    created() {
        // set timer for authentication popup (extend token expiry)
        if (this.getterIsAuth) {
            this.startAuthTimer()
        } else {
            clearInterval(this.authTimer)
        }
    },
    methods: {
        ...mapWriterActions([
            ACTION_RESET_ALL_MODULES,
            ACTION_LOGOUT_WRITER
        ]),
        startAuthTimer() {
            if (window.location.pathname.includes('widgets')) {
                clearInterval(this.authTimer)
            } else {
                this.authTimer = setInterval(async () => {
                    try {
                        const result = Auth.shouldDisplayPopup()
                        if (result) {
                            eventBus.$emit('showAuthPopup', Auth.getTimeToExpiryInSeconds())
                        }
                    } catch (error) {
                        eventBus.$emit(
                            'showSnackBar',
                            'Looks like your session has expired and you need to log in.',
                            'error',
                            true
                        )

                        // Clear client data
                        await this[ACTION_LOGOUT_WRITER]()
                        eventBus.$emit('resetPopupFlag')
                        this[ACTION_RESET_ALL_MODULES]()
                        this.$router.push({ name: 'login' })
                        clearInterval(this.authTimer)
                    }
                }, 5000)
            }
        }
    }
}
