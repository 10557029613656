export const RESET = 'RESET'

export const CLEAR_FORM = 'CLEAR_FORM'

export const LOGIN_WRITER = 'LOGIN_WRITER'

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'

export const UPDATE_WRITER_DATA = 'UPDATE_WRITER_DATA'

export const SET_WRITER_TIMEZONE = 'SET_WRITER_TIMEZONE'

export const SET_PHONE_CODE_AND_COUNTRY = 'SET_PHONE_CODE_AND_COUNTRY'

export const UPDATE_WRITER_EMAIL = 'UPDATE_WRITER_EMAIL'

export const UPDATE_WRITER_PHONE_DETAILS = 'UPDATE_WRITER_PHONE_DETAILS'

export const LOGOUT_WRITER = 'LOGOUT_WRITER'

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'

export const UPDATE_WRITER_AVATAR = 'UPDATE_WRITER_AVATAR'

export const UPDATE_WRITER_NICKNAME = 'UPDATE_WRITER_NICKNAME'

export const SET_TWOFA_STATUS = 'SET_TWOFA_STATUS'

export const SET_TOUCH_INTERVAL = 'SET_TOUCH_INTERVAL'
export const CLEAR_TOUCH_INTERVAL = 'CLEAR_TOUCH_INTERVAL'
