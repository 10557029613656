<template>
    <div
        v-if="dialog"
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Add your phone number</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="confirm-modal__content">
                <span>Please fill in your phone number</span>
                <div
                    class="phone-container"
                >
                    <phone-number
                        @phoneValidated="validatePhone"
                    />
                </div>
            </section>
            <footer>
                <div class="confirm-modal__two-button-block">
                    <button
                        class="btn-base btn-border button"
                        @click.prevent="close()"
                    >
                        skip
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/phone/Rules.js'
import { formMessages } from '@/validation/phone/Messages.js'
import { mapGetters, mapState, createNamespacedHelpers } from 'vuex'
import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber.vue'
import {
    ACTION_WRITER_UPDATE_PHONE
} from '@/store/modules/writer/action-types';
import {
    UPDATE_WRITER_PHONE_DETAILS
} from '@/store/modules/writer/mutation-types';
import { mapPhoneFields } from '@/store/modules/phone'

const {
    mapActions: mapWriterActions,
    mapMutations: mapWriterMutations
} = createNamespacedHelpers('writer');

export default {
    name: 'ConfirmModal',
    components: {
        PhoneNumber
    },
    validations: {
        ...validationRules
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapGetters('writer', [
            'getterWriterPhoneData',
            'getterNeedUpdatePhone',
            'getterIsAuth'
        ]),
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ])
    },
    watch: {
        verified(status) {
            if (status && this.dialog && this.getterIsAuth) {
                this.confirm()
            }
        }
    },
    mounted() {
        this.$store.commit('phone/SET_DEFAULT')
        eventBus.$on('validate', () => {
            this.check()
        })
    },
    methods: {
        ...mapWriterActions({
            updatePhone: ACTION_WRITER_UPDATE_PHONE
        }),
        ...mapWriterMutations([
            UPDATE_WRITER_PHONE_DETAILS
        ]),
        validationMessage: validationMessage(formMessages),
        validatePhone(value) {
            this.phoneValidationStatus = value
        },
        open() {
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        async confirm() {
            try {
                await this.updatePhone({
                    phone_code: this.country.value,
                    phone: this.phone_number
                })
                eventBus.$emit('showSnackBar', 'Your phone has been changed', 'success');
                this.close()
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
                console.error(err)
            }
        }
    }
}
</script>

<style lang="scss">
.confirm-modal {
    .form-input__fieldset {
        margin: 0;
    }
    .phone-container {
        margin: 20px 0;
    }
}
</style>
