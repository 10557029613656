import { createHelpers } from 'vuex-map-fields';

import { state } from './state.js';
import { getters } from './getters.js';
import { mutations } from './mutations.js';
import { actions } from './actions.js';

export const { mapFields: mapWizardFields } = createHelpers({
    getterType: 'wizard/getField',
    mutationType: 'wizard/updateField'
});

export const wizard = {
    namespaced: true,
    state() {
        return { ...state }
    },
    mutations,
    actions,
    getters
};
