import moment from 'moment'
import Api from '@/helpers/api/index.js'

import {
    ACTION_START_EXAM_SESSION,
    ACTION_SET_STATUS,
    ACTION_GET_QUESTION,
    ACTION_SAVE_ANSWER,
    ACTION_START_COUNTDOWN,
    ACTION_GET_EXAM_STATUS
} from './action-types';

import {
    SET_EXAM_SESSION,
    SET_EXAM_QUESTION,
    SET_EXAM_STATUS,
    SET_SAVE_ANSWER_LOADER,
    SET_COUNTDOWN,
    SET_EXAM_STATUS_FROM_API
} from './mutation-types'

export const actions = {
    async [ACTION_GET_EXAM_STATUS]({ commit }) {
        const { data } = await Api.get('/api/writer-exam/status')
        commit(SET_EXAM_STATUS_FROM_API, data)
    },
    async [ACTION_START_EXAM_SESSION]({ commit, dispatch }) {
        const { data } = await Api.get('/api/writer-exam/get-token')
        commit(SET_EXAM_SESSION, data)
        if (data.questions_left === 0) {
            dispatch(ACTION_SET_STATUS, 'finish')
        } else {
            dispatch(ACTION_START_COUNTDOWN)
            dispatch(ACTION_GET_QUESTION)
        }
    },
    [ACTION_SET_STATUS]({ commit }, payload) {
        commit(SET_EXAM_STATUS, payload)
    },
    async [ACTION_GET_QUESTION]({ commit, state }) {
        const { data } = await Api.get('/api/writer-exam/get-question', { token: state.token }, {}, false)
        commit(SET_EXAM_QUESTION, data)
    },
    async [ACTION_SAVE_ANSWER]({ commit, state, dispatch }, payload) {
        try {
            commit(SET_SAVE_ANSWER_LOADER, true)
            await Api.post('/api/writer-exam/save-answer', { token: state.token, ...payload }, {}, false)
            commit(SET_SAVE_ANSWER_LOADER, false)
            if (state.questions_left === 1) {
                dispatch(ACTION_SET_STATUS, 'finish')
            } else {
                await dispatch(ACTION_GET_QUESTION)
            }
        } finally {
            commit(SET_SAVE_ANSWER_LOADER, false)
        }
    },
    async [ACTION_START_COUNTDOWN]({ commit, getters, dispatch }) {
        const expires_data = moment(getters.getterExpires)
        // const expires_data = moment().add(1, 'minutes')
        commit(SET_COUNTDOWN, moment(expires_data).countdown().toString())
        const timerID = setInterval(async () => {
            const countdown = moment(expires_data).countdown()
            commit(SET_COUNTDOWN, countdown.toString())
            if (countdown.value > 0) {
                dispatch(ACTION_SET_STATUS, 'finish')
                clearInterval(timerID)
            }
        }, 1000);
    }

}
