<template>
    <div class="support-messenger_list">
        <loading-overlay v-if="loading" />
        <template v-else>
            <div
                v-for="(ticket) in list"
                :key="ticket.ticketid"
                class="support-messenger_ticket"
                @click="goToChat(ticket.ticketid)"
            >
                <div class="support-messenger_ticket__header">
                    <div class="support-messenger_ticket__header_top">
                        <div class="support-messenger_ticket_types">
                            <span v-if="ticket.long_names.type">{{ ticket.long_names.type }}</span>
                        </div>
                        <p class="support-messenger_ticket_date report">
                            <!--                        <span class="support-messenger_ticket_date_tool tool">-->
                            <!--                            {{ ticket.created_at | moment_short_text }}-->
                            <!--                        </span>-->
                            <span style="white-space: nowrap">{{ ticket.created_at | moment_from }}</span>
                        </p>
                    </div>
                    <b class="support-messenger_ticket_subject">«{{ ticket.subject }}»</b>
                </div>
                <div class="support-messenger_ticket__body">
                    <div class="support-messenger_ticket__body-last-message">
                        <avatar
                            :avatar-url="ticket.last_message.author.avatar_url"
                            :avatar-fallback="ticket.last_message.author.avatar_fallback"
                            :firstname="ticket.last_message.author.firstname"
                            :lastname="ticket.last_message.author.lastname"
                            :nickname="ticket.last_message.author.nickname"
                            :user="'writer'"
                        />
                        <p>
                            «{{ ticket.last_message.details }}»
                        </p>
                    </div>
                    <span class="support-messenger_ticket__bell">
                        <span
                            v-if="ticket.counters.unread_messages_count > 0"
                            class="bubble--unread"
                        >
                            {{ ticket.counters.unread_messages_count }}
                        </span>
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'
import Avatar from '@/components/common/Avatar.vue'
import LoadingOverlay from '@/components/LoadingOverlay';

export default {
    components: {
        Avatar,
        LoadingOverlay
    },
    mixins: [
        filtersMixin
    ],
    props: {
        list: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        goToChat(id) {
            this.$emit('goToChat', id)
        }
    }
}
</script>
