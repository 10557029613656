<template>
    <div class="global_update">
        <transition-group name="globalUpdate">
            <template v-for="item in myComponents">
                <component
                    :is="item.component"
                    v-if="item.isShow"
                    :key="item.name"
                    :ref="item.name"
                    @close="onClose(item.name)"
                    @proceed="onProceed(item.name)"
                />
            </template>
        </transition-group>
        <phone-add-modal ref="phone_update_modal" />
        <general-info-update-modal ref="general_info_update_modal" />
        <exam-pass-modal ref="exam_pass_modal" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PhoneAddNotification from '@/components/update-notifications/PhoneAddNotification.vue'
import ExamPassNotification from '@/components/update-notifications/ExamPassNotification.vue'
import GeneralInfoUpdateNotification from '@/components/update-notifications/GeneralInfoUpdateNotification.vue'
import PhoneAddModal from '@/components/modals/PhoneAddModal.vue'
import GeneralInfoUpdateModal from '@/components/modals/GeneralInfoUpdateNotification.vue'
import ExamPassModal from '@/components/modals/ExamPassNotification.vue'

export default {
    name: 'GlobalUpdateNotifications',
    components: {
        PhoneAddNotification,
        ExamPassNotification,
        GeneralInfoUpdateNotification,
        PhoneAddModal,
        GeneralInfoUpdateModal,
        ExamPassModal
    },
    data() {
        return {
            showPhone: false,
            showExam: false,
            showApplications: false
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterNeedUpdatePhone',
            'getterNeedUpdateExam',
            'getterNeedUpdateGeneralInfo',
            'getterIsAuth'
        ]),
        myComponents() {
            return [
                {
                    component: PhoneAddNotification,
                    name: 'phone',
                    isShow: this.showPhone
                },
                {
                    component: ExamPassNotification,
                    name: 'exam',
                    isShow: this.showExam
                },
                {
                    component: GeneralInfoUpdateNotification,
                    name: 'applications',
                    isShow: this.showApplications
                }
            ]
        }
    },
    watch: {
        getterIsAuth(value) {
            if (value) {
                setTimeout(() => {
                    if (this.getterNeedUpdatePhone) {
                        this.showPhone = true
                    }
                    if (this.getterNeedUpdateExam) {
                        this.showExam = true
                    }
                    if (this.getterNeedUpdateGeneralInfo) {
                        this.showApplications = true
                    }
                }, 2000)
            } else {
                this.showPhone = false
                this.showExam = false
                this.showApplications = false
            }
        }
    },
    methods: {
        onClose(name) {
            switch (name) {
            case 'phone':
                this.showPhone = false
                break;
            case 'exam':
                this.showExam = false
                break;
            case 'applications':
                this.showApplications = false
                break;
            default:
                break;
            }
        },
        onProceed(name) {
            switch (name) {
            case 'phone':
                this.$refs.phone_update_modal.open()
                break;
            case 'exam':
                this.$refs.exam_pass_modal.open()
                break;
            case 'applications':
                this.$refs.general_info_update_modal.open()
                break;
            default:
                break;
            }
        }
    }
}
</script>

<style lang="scss">
    .global_update {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 0 10px;
        width: 340px;
        &-modal {
            &__dialog {
                position: relative;
                background: #fff;
                box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.58), 0px 2px 6px -2px rgba(16, 24, 40, 0.53);
                border-radius: 4px;
                margin-bottom: 15px;
            }
            &__header {
                font-weight: 500;
                color: $font-color-main;
                font-size: 16px;
                border-bottom: 1px solid $border-light;
                padding: 15px 10px;
                position: relative;
            }
            &__close {
                position: absolute;
                right: 0;
                top: 0;
                min-width: 20px;
                width: 20px;
                height: 20px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 12px;
                    height: 12px;
                    background: url("~@/assets/img/svg/close.svg") no-repeat;
                    background-size: contain;
                    transform: translate(-50%, -50%);
                }
                transform: translate(50%, -50%);
                color: #4F4F4F;
                background: #fff;
                border-radius: 50%;
                border: 1px solid;
                cursor: pointer;
            }
            &__content {
                padding: 15px 10px;
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 14px;
                line-height: 1.3;
                cursor: pointer;
            }
            &__btns {
                display: flex;
                justify-content: space-between;
                padding: 15px 10px;
            }
            &__icon {
                &-alert {
                    margin-right: 5px;
                    color: $error-color;
                }
                &-link {
                    margin-left: auto;
                }
            }
        }
    }
    .globalUpdate-enter-active,
    .globalUpdate-leave-active {
        transition: all 1s;
        transform: translateX(0);
    }
    .globalUpdate-enter,
    .globalUpdate-leave-to
    {
        opacity: 0;
        transform: translateX(-100%);
    }
</style>
