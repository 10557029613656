import { getField } from 'vuex-map-fields'

export const getters = {
    getField,
    isPhoneVerified(state) {
        return state.verified
    },
    getPhoneNumber(state) {
        return state.form_data.country.phone_number
    }
}
