<template>
    <div class="hint-wrapper">
        <img
            src="~@/assets/img/svg/info.svg"
            alt="info"
            class="hint-image"
        >
        <p class="hint-text">
            {{ text }}
        </p>
    </div>
</template>
<script>
export default {
    name: 'Hint',
    props: {
        text: {
            type: String
        }
    }
}
</script>
<style lang="scss">
    .hint {
        &-wrapper {
            display: flex;
            align-items: center;
            margin: 10px 0;
        }
        &-text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: $font-color-main;
            padding-right: 60px;
        }
        &-image {
            margin-right: 10px;
        }
    }
    @media (max-width: 768px) {
        .hint {
            &-wrapper {
                padding: 15px;
                background: #F8F8F8;
                border-radius: 4px;
                align-items: flex-start;
            }
            &-text {
                padding-right: 0;
            }
        }
    }
</style>
